// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    editing: false,
    updated: false,
    theme: {
      name: "",
      description: ""
    },
    palette: null
};

// ===========================|| GALLERY REDUCER ||=========================== //

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_STYLE: {
          console.log("Initializing style: ", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              theme: action.payload.theme,
              palette: action.payload.palette
          };
        }
        case actionTypes.SET_EDITING: {
          console.log("Setting Style Editing:", action.payload);

          // Update state
          return {
              ...state,
              editing: action.payload
          };
        }
        case actionTypes.SET_UPDATED: {
          console.log("Marking as Updated:", action.payload);

          // Update state
          return {
              ...state,
              updated: action.payload
          };
        }
        case actionTypes.SET_PALETTE: {
          //console.log("Updating Palette:", action.payload);

          // Update state
          return {
              ...state,
              palette: action.payload
          };
        }
        default:
            return state;
    }
};

export default galleryReducer;
