/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// React Print
import ReactToPrint from 'react-to-print';

// Compoennts
import QrCodeStylingComponent from "components/QRCode";

// Mobile Simulator
function DisplayCard({ profile, portfolio, dispatch }) {
  // QR Code container
  const displayCard = useRef();
  const qrCode = useRef();
  const [dimensions, setDimensions] =  useState({ width: 0, height: 0 });
  useEffect(() => {
    if (qrCode.current) {
      setDimensions({
        width: qrCode.current.offsetWidth,
        height: qrCode.current.offsetHeight,
      });
    }
  }, []);

  return (
    <Row>
      <Col md="12">
        <Card className="mt-4">
          <CardHeader>
            <Row>
              <Col md="6">
                <h3 className="mb-0">Gallery Display Card - 3" x 5"</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="mt-2 mb-2">
              <Col md="12">
                <div ref={displayCard} style={{
                  width: `5in`,
                  height:`3in`,
                  borderRadius: '15px',
                  marginBottom: 20,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '1px solid black',
                  display: 'flex'
                }}>
                  <div className="card-left" style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: `2.5in`,
                    height:`3in`,
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <div
                      className="card-details-title"
                      style={{
                        marginTop: '0.5in',
                        marginLeft: '0.25in',
                        marginBottom: '0.1in'
                      }}
                    >
                      <h2
                        style={{
                          fontSize: '130%'
                        }}
                      >
                        {(portfolio.artwork.selected) ? `${portfolio.artwork.selected.artwork.name}` : ''}
                      </h2>
                    </div>
                    <div
                      className="card-details-artist"
                      style={{
                        display: 'flex',
                        marginLeft: '0.1in'
                      }}
                    >
                      <div>
                        <img
                          alt="..."
                          src={(profile.initialized) ? profile.user.account.imageUrl : "https://storage.googleapis.com/avant-garde-cdn/axon/assets/img/user.png" }
                          style={{
                            width: '0.4in',
                            height: '0.4in',
                            borderRadius: '0.4in',
                            marginLeft: '0.2in'
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: '0.15in'
                        }}
                      >
                        <h4
                          style={{
                            marginBottom: 0
                          }}
                        >
                          {(profile.initialized) ? profile.user.name : "" }
                        </h4>
                        <small
                          style={{
                            fontSize: '60%',
                            position: 'relative'
                          }}
                        >{ portfolio.config && `@${portfolio.config.handle}`}</small>
                      </div>
                    </div>
                    <div
                      className="card-details-description"
                    >
                      <div
                        style={{
                          marginTop: '0.15in',
                          marginLeft: '0.15in'
                        }}
                      >
                      <p
                        style={{
                          paddingLeft: '0.2in',
                          paddingRight: '0.2in',
                          fontSize: '60%'
                        }}
                      >
                        { portfolio.artwork.selected.artwork.description && `${portfolio.artwork.selected.artwork.description}`}
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-right" style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: `2.5in`,
                    height:`3in`,
                  }}>
                    <div
                      id="gallery-qr-code"
                      ref={qrCode}
                      style={{ width: '2.25in', height: '2.75in', paddingTop: '0.15in' }}
                    >
                      <QrCodeStylingComponent
                        data={`https://my.arthaus.app/wendy-hogue-berry/editions/13cc767a-cacf-4e42-a04e-845b2de58b14`}
                        width={dimensions.width}
                        height={dimensions.height}
                        bgColor="white"
                        fgColor="#252724"
                        eyeColor="#88765D"
                        dotColor="#88765D"
                        centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/brands/arthaus/assets/easel.gold.svg"
                      ></QrCodeStylingComponent>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Button
                  className="mb-4"
                  color="secondary"
                  onClick={() => {

                  }}
                  block
                >
                  Send Display Card
                </Button>
              </Col>
              <Col md="6">
                <Button
                  className="mb-4"
                  color="primary"
                  onClick={() => {

                  }}
                  block
                >
                  <ReactToPrint
                    trigger={() => <span>Print Display Card</span>}
                    content={() => displayCard.current}
                  />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

// Connect to store
const ConnectedDisplayCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(DisplayCard);

export default ConnectedDisplayCard;


/*

<div ref={displayCard} style={{
  width: `5in`,
  height:`3in`,
  textAlign: 'center',
  backgroundColor: '#CB2B2F',
  borderRadius: '15px',
  marginLeft: 'auto',
  marginRight: 'auto',
  border: '1px solid black'
}}>
  <Card className="ml-3 mr-3">
    <CardBody>
      <Row>
        <Col sm="6">
          <Row className="mt-2">
            <Col md="12 mt-4 text-left">
              <h2>{(portfolio.artwork.selected) ? `${portfolio.artwork.selected.artwork.name}` : ''}</h2>
            </Col>
            <Col md="12 mt-2 ml-2">
              <Row>
                <Col className="col-auto">
                  <a
                    className="avatar rounded-circle"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      src={(profile.initialized) ? profile.user.account.imageUrl : "https://storage.googleapis.com/avant-garde-cdn/axon/assets/img/user.png" }
                    />
                  </a>
                </Col>
                <div className="col ml-2">
                  <h4 className="mb-0">
                    <a href="" onClick={(e) => e.preventDefault()}>
                      {(profile.initialized) ? profile.user.name : "" }
                    </a>
                  </h4>
                  <small>{ portfolio.config && `@${portfolio.config.handle}`}</small>
                </div>
              </Row>
            </Col>
            <Col md="12 mt-4">
              <blockquote className="blockquote">
                <p className="mt-2 mr-2 ml-2">
                  { portfolio.artwork.selected.artwork.description && `${portfolio.artwork.selected.artwork.description}`}
                </p>
              </blockquote>
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <div
            id="gallery-qr-code"
            ref={qrCode}
            style={{ width: '1.5in', height: '5in' }}
          >
            <QrCodeStylingComponent
              data={`https://www.google.com`}
              width={dimensions.width}
              height={dimensions.height}
              bgColor="white"
              fgColor="#252724"
              eyeColor="#88765D"
              dotColor="#88765D"
              centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/brands/arthaus/assets/easel.gold.svg"
            ></QrCodeStylingComponent>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
</div>



*/
