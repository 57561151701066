/*
 *  Edit Link: Newsletter
 */

// Imports
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// UI Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// Initial Card Data
const initialFormState = {
  title: '',
  description: null,
  signup: {
    title: '',
    description: '',
    action: {
      label: ''
    },
    placeholder: ''
  }
};

// List container
export const EditNewlsetter = ({ id, data, onUpdate }) => {
  // States
  const [formData, setFormData] = useState(data ? data : initialFormState);

  // Return
  return(
    <>
      <div className="modal-body">
        <Form>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-title"
                  >
                    Title
                  </label>
                  <Input
                    id="input-title"
                    type="text"
                    value={formData.title}
                    onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-description"
                  >
                    Description
                  </label>
                  <Input
                    id="input-description"
                    type="text"
                    value={formData.description}
                    onChange={e => setFormData({ ...formData, 'description': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-title"
                  >
                    Sign-Up Button - Title
                  </label>
                  <Input
                    id="input-title"
                    type="text"
                    value={formData.signup.title}
                    onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-title"
                  >
                    Sign-Up Button - Description
                  </label>
                  <Input
                    id="input-title"
                    type="text"
                    value={formData.signup.title}
                    onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          onClick={() => {
            // Call Update
            onUpdate(id, formData);
          }}
          size="xl"
        >
          Update Link
        </Button>
      </div>
    </>
  )
}
