/*
 *  Edit Link: Hero
 */

// Imports
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';


// UI Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardImgOverlay,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// Initial Card Data
const initialFormState = {
  title: '',
  description: '',
  image: {
    url: ''
  },
  link: {
    url: ''
  }
};

// List container
export const EditHero = ({ id, data, onUpdate }) => {
  // States
  const [formData, setFormData] = useState(data ? data : initialFormState);

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const uploadFile = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleFile = async (event) => {
    event.preventDefault();

    // Grab uploaded file
    const fileUploaded = event.target.files[0];
    console.log("Upload File: ", fileUploaded);

    // Upload to CDN (get form data)
    const fileData = new FormData ();
    fileData.append("file", fileUploaded);
    fileData.append("upload_preset", "byu7xlhm");

    // Upload (Cloudinary)
    Axios.post(
      "https://api.cloudinary.com/v1_1/dwe9idkgv/image/upload",
      fileData
    ).then((response) => {
      // Update image location
      console.log("Image URL: ", response.data.secure_url);
      const imageUrl = response.data.secure_url;
      setFormData({ ...formData, 'image': { 'url': imageUrl }});

      // Update data
      onUpdate(id, formData);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  // Return
  return(
    <>
      <div className="modal-body">
        <Form>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <Card style={{
                  height: 200,
                  backgroundImage: `url(${formData.image.url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}>
                  <CardBody>
                    <Button color="secondary" size="lg"
                      onClick={uploadFile} style={{
                      position: 'absolute',
                      bottom: 20,
                      right: 20
                    }}>
                      <span className="btn-inner--icon mr-2">
                        <i className="ni ni-single-copy-04" />
                      </span>
                      <span className="btn-inner--text">Upload Image</span>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleFile}
                        style={{display: 'none'}}
                      />
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-title"
                  >
                    Title
                  </label>
                  <Input
                    id="input-title"
                    type="text"
                    value={formData.title}
                    onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-description"
                  >
                    Description
                  </label>
                  <Input
                    id="input-description"
                    type="text"
                    value={formData.description}
                    onChange={e => setFormData({ ...formData, 'description': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-link-url"
                  >
                    Website URL
                  </label>
                  <Input
                    id="input-link-url"
                    type="text"
                    value={formData.link.url}
                    onChange={e => setFormData({ ...formData, 'link': { 'url': e.target.value }})}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          onClick={() => {
            // Call Update
            onUpdate(id, formData);
          }}
          size="xl"
        >
          Update
        </Button>
      </div>
    </>
  )
}
