/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// imports
import _ from 'lodash';

// Rich Text Editor (Quill)
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

// Firestore
import { firestore } from "./../../../../firebase";

// API (Axon)
import Arthaus from '@axon/arthaus';

// // Format biography
// function formatBiography(description, headline) {
//   if(headline) {
//     return `
//       <h3>${headline}</h3>
//       <p><br></p>
//       <p>${description}</p>
//     `;
//   } else {
//     return `
//       <p>${description}</p>
//     `;
//   }
// }

// Mobile Simulator
function BiographyCard({ profile, portfolio, dispatch }) {
  // States (UX)
  const [editing, setEditing] = useState(false);

  // Content Editor
  const [seedBiography, setSeedBiography] = useState('');
  const [headline, setHeadline] = useState('');
  const [knownFor, setKnownFor] = useState('');
  const [summary, setSummary] = useState('');
  const [biography, setBiography] = useState('');

  // Update text
  const onBiographyUpdated = (newValue) => {
    if (newValue !== biography) {
      // Update
      // setBiography(newValue);

      // // Creaet editing
      // setEditing(true);
    }
  };

  // Set initial seed values
  useEffect(() => {
    if(portfolio && portfolio.config) {
      // Set seed bio
      setSeedBiography(portfolio.config.bio);

      // Set generated values
      if(portfolio.config.insights) {
        // Headline
        if(portfolio.config.insights.artist.overview.headline) {
          setHeadline(portfolio.config.insights.artist.overview.headline);
        }

        // Known For
        if(portfolio.config.insights.artist.overview.known_for) {
          setKnownFor(portfolio.config.insights.artist.overview.known_for);
        }

        // Summary
        if(portfolio.config.insights.artist.overview.long_description) {
          setSummary(portfolio.config.insights.artist.overview.long_description);
        }

        // Set Biography
        if(portfolio.config.insights.artist.overview.biography.html) {
          setBiography(portfolio.config.insights.artist.overview.biography.html);
        }
      }
    }
  }, [portfolio]);

  // Save profile
  // const saveProfileBiography = async (description, headline, biography) => {
  //   console.log("Updating Portfolio Content: ", description, headline, biography);
  //
  //   // Update portfolios
  //   const portfoliosRef = firestore.collection("portfolios").doc(profile.user.uid);
  //   const updatedPortfolio = await portfoliosRef.set(
  //     {
  //       apps: {
  //         profile: {
  //           content: {
  //             description: description,
  //             headline: headline,
  //             biography: biography
  //           }
  //         }
  //       }
  //     },
  //     { merge: true }
  //   );
  //
  //   // Creaet editing
  //   setEditing(false);
  // }

  return (
    <Row>
      <Col md="12" className="mt-4">
        <Form>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="artwork-short-description"
              md="2"
            >
              Seed Biography
            </Label>
            <Col md="10">
            <Input
              id="artist-seed-biography"
              defaultValue={seedBiography}
              resize="none"
              rows="3"
              type="textarea"
            />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="artwork-short-description"
              md="2"
            >
              Headline
            </Label>
            <Col md="10">
            <Input
              id="artist-seed-biography"
              value={headline}
              resize="none"
              rows="1"
              type="textarea"
            />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="artwork-short-description"
              md="2"
            >
              Known For
            </Label>
            <Col md="10">
            <Input
              id="artist-seed-biography"
              defaultValue={knownFor}
              resize="none"
              rows="2"
              type="textarea"
            />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="artwork-short-description"
              md="2"
            >
              Short Biography
            </Label>
            <Col md="10">
            <Input
              id="artist-seed-biography"
              defaultValue={summary}
              resize="none"
              rows="6"
              type="textarea"
            />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="2"
            >
              Full Biography
            </Label>
            <Col md="10">
              <ReactQuill theme="snow" value={biography} onChange={onBiographyUpdated} />
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  )
}

// Connect to store
const ConnectedBiographyCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(BiographyCard);

export default ConnectedBiographyCard;
