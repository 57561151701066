/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

// react library for routing
import { useParams } from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Rich Text Editor (Quill)
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardImg,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Load config
import config from "config";

// Firebase
import { firestore } from "./../../../../firebase";

// Auth actions
import {
  SET_ACTIVE_ARTWORK
} from 'store/actions';

// core components
import PortfolioHeader from "components/Headers/PortfolioHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function ViewArtwork({ profile, portfolio, dispatch }) {
  // Get context
  const { id } = useParams();
  console.log("View Artwork: ", id, portfolio);

  // States (Navigation)
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  // Content Editor
  const [headline, setHeadline] = useState(
    (portfolio.artwork.selected &&
     portfolio.artwork.selected.image.summary &&
     portfolio.artwork.selected.image.summary.headline) ? portfolio.artwork.selected.image.summary.headline : '');
  const [summary, setSummary] = useState(
    (portfolio.artwork.selected &&
     portfolio.artwork.selected.image.summary &&
     portfolio.artwork.selected.image.summary.short) ? portfolio.artwork.selected.image.summary.short : '');
  const [commentary, setCommentary] = useState(
    (portfolio.artwork.selected &&
     portfolio.artwork.selected.content &&
     portfolio.artwork.selected.content.editorial &&
     portfolio.artwork.selected.content.editorial.full_text) ? portfolio.artwork.selected.content.editorial.full_text : '');
  const onCommentaryUpdated = (newValue) => {
    if (newValue !== commentary) {
      console.log(newValue);
      setCommentary(newValue);
    }
  };

  // Check active artwork
  if(portfolio && !portfolio.artwork.selected) {
    // Query for ID
    firestore.collection("artworks").doc(id)
      .onSnapshot((doc) => {
        // Parse data
        let artworkData = doc.data();
        console.log("Artwork Data: ", artworkData);

        // Set initial data
        if(artworkData && artworkData.image && artworkData.image.summary) {
          setHeadline(artworkData.image.summary.headline);
          setSummary(artworkData.image.summary.short);
        }
        if(artworkData && artworkData.content && artworkData.content.editorial && artworkData.content.editorial.full_text) {
          setCommentary(artworkData.content.editorial.full_text);
        }

        // Update local store
        dispatch({
          type: SET_ACTIVE_ARTWORK,
          payload: artworkData
        });
      });
  }

  return (
    <>
      <PortfolioHeader
        title="Artwork Title"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        collapsed={true}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Card>
              <CardImg
                alt="..."
                src={`https://picasso.arthaus.cloud/cache/artworks/${id}.jpeg`}
                top
              />

              <CardBody>
                <CardTitle className="h2 mb-0">
                  {`${(portfolio.artwork.selected && portfolio.artwork.selected.artwork) ? portfolio.artwork.selected.artwork.name : ''}`}
                </CardTitle>

                <Row className="mt-4">
                  <Col md="12">
                    { portfolio.artwork.labels.map((label, index) => (
                      <Button
                        color="primary" size="sm" type="button" key={index}
                        style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}
                      >
                        {label.description}
                      </Button>
                    ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-3" tag="h3">
                  Color Pallete
                </CardTitle>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Dominant
                  </Label>
                  <Col md="6">
                    { (portfolio.artwork.selected && portfolio.artwork.selected.image) &&
                      <Button
                        className="btn-icon-only rounded-circle"
                        type="button"
                        style={{
                          backgroundColor: `rgba(${portfolio.artwork.selected.image.palette.dominant.r}, ${portfolio.artwork.selected.image.palette.dominant.g}, ${portfolio.artwork.selected.image.palette.dominant.b}, 1.0)`,
                          marginLeft: 4,
                          marginBottom: 4,
                          marginTop: 5
                        }}
                      >
                      </Button>
                    }
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <Row>
                  <Col md="1"></Col>
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="4"
                  >
                    Extracted
                  </Label>
                  <Col md="6">
                    { portfolio.artwork.selected && portfolio.artwork.selected.image && portfolio.artwork.selected.image.palette.colors.map((color, index) => (
                      <Button
                        className="btn-icon-only rounded-circle"
                        type="button"
                        key={index}
                        style={{
                          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 1.0)`,
                          marginLeft: 4,
                          marginBottom: 4,
                          marginTop: 5
                        }}
                      >
                      </Button>
                    ))}
                  </Col>
                  <Col md="1"></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Artwork Details</h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { setActiveTab('1'); }}
                    >
                      Enhanced Content
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row className="mt-4">
                      <Col sm="12">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="artwork-description"
                              md="2"
                            >
                              Description
                            </Label>
                            <Col md="10">
                              <Input
                                id="artwork-description"
                                type="text"
                                value={(portfolio.artwork.selected && portfolio.artwork.selected.artwork && portfolio.artwork.selected.artwork.description) ? portfolio.artwork.selected.artwork.description : ''}
                                disabled
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="artwork-headline"
                              md="2"
                            >
                              Headline
                            </Label>
                            <Col md="10">
                              <Input
                                value={headline}
                                id="artwork-headline"
                                type="text"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="artwork-summary"
                              md="2"
                            >
                              Summary
                            </Label>
                            <Col md="10">
                            <Input
                              id="artwork-summary"
                              value={summary}
                              resize="none"
                              rows="7"
                              type="textarea"
                            />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="artwork-summary"
                              md="2"
                            >
                              Themes
                            </Label>
                            <Col md="10">
                              { ( portfolio.artwork.selected &&
                                  portfolio.artwork.selected.enrichments &&
                                  portfolio.artwork.selected.enrichments.themes
                                ) && portfolio.artwork.selected.enrichments.themes.map((label, index) => (
                                <Button
                                  color="secondary" size="sm" type="button" key={index}
                                  style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}
                                >
                                  {label}
                                </Button>
                              ))}
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="2"
                            >
                              Editorial
                            </Label>
                            <Col md="10">
                              <ReactQuill theme="snow" value={commentary} onChange={onCommentaryUpdated} />
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewArtwork = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(ViewArtwork);

export default ConnectedViewArtwork;


/*

<Card className="bg-primary">
  <CardHeader className="bg-transparent">
    <Row className="align-items-center">
      <div className="col">
        <h6 className="text-light text-uppercase ls-1 mb-1">
          Insights
        </h6>
        <h5 className="h3 text-white mb-0">Traffic vs Sales</h5>
      </div>
      <div className="col">
        <Nav className="justify-content-end" pills>
          <NavItem className="mr-2 mr-md-0">
            <NavLink
              className={classnames("py-2 px-3", {
                active: activeNav === 1,
              })}
              href=""
              onClick={(e) => toggleNavs(e, 1)}
            >
              <span className="d-none d-md-block">Keywords</span>
              <span className="d-md-none">M</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-2 px-3", {
                active: activeNav === 2,
              })}
              data-toggle="tab"
              href=""
              onClick={(e) => toggleNavs(e, 2)}
            >
              <span className="d-none d-md-block">Trends</span>
              <span className="d-md-none">W</span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </Row>
  </CardHeader>
  <CardBody>
    <div className="chart">
      <Line
        data={chartExample1[chartExample1Data]}
        options={chartExample1.options}
        id="chart-sales-dark"
        className="chart-canvas"
      />
    </div>
  </CardBody>
</Card>


<CardText className="mt-4">
  {`${(portfolio.artwork.selected && portfolio.artwork.selected.artwork && portfolio.artwork.selected.artwork.description) ? portfolio.artwork.selected.artwork.description : ''}`}
</CardText>

*/
