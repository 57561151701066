/*
    Artists API
 */

// Imports (external)
import { request, gql } from 'graphql-request';

// Import Configuration
import config from '../config';

// Orders
const Artists = {
  // Find Artist by ID
  get: function(params, callback) {
    // Request storefronts from API
    const query = gql`
      query FindArtists($artistId: Int) {
        getArtist(artistId: $artistId) {
          artistic_name
          bio
          paypal
          shopify_collection_id
          shopify_collection_handle
          user_id
          user {
            email
            first_name
            last_name
          }
        }
      }
    `

    // Execute query
    request(config.api.url, query, {
      artistId: params.artistId
    }).then((data) => {
      // Parse data
      const artist = (data.getArtist) ? data.getArtist : null;

      // Return data
      callback(artist);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback(null);
    });
  }
}

// Export module
export default Artists;
