/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// API (Axon)
import Arthaus from '@axon/arthaus';

// Config
import config from "config";

// Authentication
import { firestore } from "./../../../../firebase";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal
} from "reactstrap";

// Draggable
// import { Draggable } from "react-drag-reorder";
import Links from './Links'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Actions
import {
  RENDER_PORTFOLIO
} from 'store/actions';

// Initial Card Data
const initialFormState = {
  title: '',
  description: '',
  link: {
    url: ''
  }
};

// Mobile Simulator
function LinksCard({ links, profile, portfolio, dispatch }) {
  // Local States
  const [formData, setFormData] = useState(initialFormState);
  const [linkModal, setLinkModal] = React.useState(false);

  // Sort links
  let cards = _.orderBy(links, 'position', 'asc');

  // Update position function
  const handleReorder = async (updatedLinks) => {
    console.log("Updated Links: ", updatedLinks);

    // Update position
    for (var i = 0; i < updatedLinks.length; i++) {
      // Set position
      updatedLinks[i].position = i;
    }

    // Store updated links
    if(profile && profile.initialized) {
        // Save to firestore
        const portfoliosRef = await firestore.collection("portfolios").doc(profile.user.uid);
        await portfoliosRef.set({
          apps: {
            profile: {
              links: updatedLinks
            }
          }
        }, { merge: true });
    }
  }

  // Update data
  const handleUpdate = async (updatedLinks) => {
    console.log("Updated Links: ", updatedLinks);

    // Store updated links
    if(profile && profile.initialized) {
        // Save to firestore
        const portfoliosRef = await firestore.collection("portfolios").doc(profile.user.uid);
        await portfoliosRef.set({
          apps: {
            profile: {
              links: updatedLinks
            }
          }
        }, { merge: true });
    }
  }

  // Add link
  const createLink = async () => {
    // Create and add link to cards
    let newLink = {};

    // Set meta-data
    newLink.id = Date.now();
    newLink.position = cards.length;
    newLink.type = "link";
    newLink.data = formData;

    // Add to cards
    let updatedLinks = links;
    updatedLinks.push(newLink);

    // Add to cards
    cards.push(newLink);

    // Update
    handleUpdate(updatedLinks);
  }

  return (
    <Row>
      <Col md="12" className="mt-4">
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Edit Your Links</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-neutral btn-round btn-icon"
                  color="primary"
                  href=""
                  onClick={(e) => setLinkModal(true) }
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-link" />
                  </span>
                  <span className="btn-inner--text">Add New Link</span>
                </Button>
                <Modal
                  className="modal-dialog-centered"
                  isOpen={linkModal}
                  toggle={() => setLinkModal(false)}
                  size="lg"
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                      Add New Link
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => setLinkModal(false)}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Form>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Title
                              </label>
                              <Input
                                id="input-title"
                                type="text"
                                value={formData.title}
                                onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-description"
                              >
                                Description
                              </label>
                              <Input
                                id="input-description"
                                type="text"
                                value={formData.description}
                                onChange={e => setFormData({ ...formData, 'description': e.target.value})}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-link-url"
                              >
                                Website URL
                              </label>
                              <Input
                                id="input-link-url"
                                type="text"
                                value={formData.link.url}
                                onChange={e => setFormData({ ...formData, 'link': { url: e.target.value }})}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="primary"
                      onClick={() => {
                        // Call Update
                        createLink();

                        // Close the link modal
                        setLinkModal(false);
                      }}
                      size="xl"
                    >
                      Create
                    </Button>
                  </div>
                </Modal>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <div className="flex-container">
                  <div className="row" key={cards.length}>
                  { (cards && cards.length > 0) &&
                    <DndProvider backend={HTML5Backend}>
                      <Links links={cards} onReorder={handleReorder} onUpdate={handleUpdate} />
                    </DndProvider>
                  }
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

// Connect to store
const ConnectedLinksCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(LinksCard);

export default ConnectedLinksCard;
