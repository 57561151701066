// react library for routing
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

import { BrowserView, MobileView, isMobile } from 'react-device-detect';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";

function IndexHeader() {
  // States
  const [ email, setEmail ] = useState('');

  return (
    <>
      <div className="header bg-info pt-6 pb-6">
        <Container>
          <div className="header-body">
            <BrowserView>
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-5">
                    <h1 className="display-2 text-white font-weight-bold mb-2">
                      The Platform for Independent Artists
                    </h1>
                    <h2 className="display-5 text-white font-weight-light">
                      Manage your portfolio, audience and patrons all in one easy-to-use platform built for independent artists.
                    </h2>
                    <div className="mt-5">
                      <Form>
                        <FormGroup className="row" style={{
                          maxWidth: 450,
                          paddingLeft: 15
                        }}>
                        <p className="mt-2" style={{
                          color: '#C7B078',
                          fontSize: '80%',
                          position: 'relative',
                          left: 0
                        }}>Sign-up for early access to the private Beta.</p>
                          <Input
                            id="artist-signup"
                            defaultValue={email}
                            placeholder="Email address..."
                          />
                          <Button color="default" style={{
                            color: '#353B40',
                            position: 'relative',
                            top: -44,
                            left: 'calc(100% - 132px)',
                            height: 41,
                            width: 130
                          }}>Join Waitlist</Button>

                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col>
                  <img
                    alt="..."
                    className="img-fluid pl-7"
                    src={require("assets/img/landing/easel.hero.png").default}
                  />
                </Col>
              </Row>
            </BrowserView>
            <MobileView>
              <Row className="align-items-center">
                <Col lg="6">
                  <img
                    alt="..."
                    className="img-fluid pt-0 pl-6 pr-6 pb-2"
                    src={require("assets/img/landing/easel.hero.png").default}
                  />
                </Col>
                <Col lg="6">
                  <div className="mt-2 pl-2 pr-2">
                    <h1 className="display-2 text-white font-weight-bold mb-2">
                      The Platform for Independent Artists
                    </h1>
                    <h2 className="display-5 text-white font-weight-light">
                      Manage your portfolio, audience and patrons all in one easy-to-use platform built for independent artists.
                    </h2>
                    <div className="mt-5 pr-3">
                      <Form>
                        <FormGroup className="row" style={{
                          maxWidth: 450,
                          paddingLeft: 15
                        }}>
                        <p className="mt-2" style={{
                          color: '#C7B078',
                          fontSize: '80%',
                          position: 'relative',
                          left: 50
                        }}>Sign-up for early access to the private Beta.</p>
                          <Input
                            id="artist-signup"
                            defaultValue={email}
                            placeholder="Email address..."
                          />
                          <Button color="default" style={{
                            color: '#353B40',
                            position: 'relative',
                            top: -44,
                            left: 'calc(100% - 132px)',
                            height: 41,
                            width: 130
                          }}>Join Waitlist</Button>

                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </MobileView>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
