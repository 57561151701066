/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Firebase
import { firestore } from "./../../../firebase";

// Auth actions
import {
  SET_ACTIVE_ARTWORK
} from 'store/actions';

// core components
import ShowcaseHeader from "components/Headers/ShowcaseHeader.js";

// Search Bar
import Search from "components/Search/Search.js";
import SearchEditions from "components/Search/SearchEditions.js";

// showcase components
import PaletteConfigurator from "components/Style/Palette.js";
import MobileSimulator from "components/Simulator/Mobile.js";

// Tabs
import DisplayCard from "./tabs/DisplayCard";
//import QRCode from "./tabs/QRCode";

// Helper - create encoded context
function encodeContext(artwork) {
  // Create context
  const context = {
    "artworks": [
      {
        "id": `${artwork.id}`,
        "type": "artwork",
        "geometry": {
          "height": 16,
          "width": 12,
          "depth": 0.01
        },
        "position": {
          "x": 0.4,
          "y": 1.9,
          "z": -20
        },
        "rotation": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "data": {
          "name": artwork.name,
          "image": {
            "url": `https://picasso.arthaus.cloud/cache/artworks/${artwork.file_name}.webp`
          }
        }
      }
    ]
  };

  // Encode and return
  console.log("Encoded Context: ", btoa(JSON.stringify(context)));
  return btoa(JSON.stringify(context));
}

function VirtualGallery({ profile, portfolio, dispatch }) {
  // States (UX)
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  // Site Simulator
  const [url, setUrl] = React.useState('https://localhost:1234');
  const [type, setType] = React.useState('blank');

  // Set target artwork
  const selectArtwork = (artwork) => {
    console.log("Selected Artwork: ", artwork);

    // Query for ID
    firestore.collection("artworks").doc(artwork.id)
      .onSnapshot((doc) => {
        // Parse data
        let artworkData = doc.data();

        // Update local store
        dispatch({
          type: SET_ACTIVE_ARTWORK,
          payload: artworkData
        });

        // Update gallery preview
        setUrl(`https://localhost:1234?context=${encodeContext(artworkData.artwork)}`);
        setType('iframe');
      });
  }

  // (optional) Select specific edition
  const selectEdition = (edition) => {
    console.log("Selected Edition: ", edition);
  }

  return (
    <>
      <ShowcaseHeader
        title="Virtual Gallery"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="7">
            <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
              <CardBody>
                <Row>
                  <Col xl="8">
                    <Row>
                      <Col xl="12">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label text-white"
                              htmlFor="selected-artwork"
                              md="4"
                            >
                              Artwork
                            </Label>
                            <Col md="8">
                              <Search index="artworks" onSelect={selectArtwork} />
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label text-white"
                              htmlFor="selected-experience"
                              md="4"
                            >
                              Edition
                            </Label>
                            <Col md="8">
                              <SearchEditions index="editions" onSelect={selectEdition} />
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="4">
                    <Card
                      style={{
                        position: 'absolute',
                        top: -50,
                        width: '90%'
                      }}
                    >
                      { portfolio.artwork.selected &&
                        <CardImg
                          alt="..."
                          src={`https://picasso.arthaus.cloud/cache/artworks/${portfolio.artwork.selected.artwork.file_name}.webp`}
                          style={{ height: '200px', objectFit: 'cover' }}
                          top
                        />
                      }
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col md="9">
                    <h3 className="mb-0">Configure Your Gallery</h3>
                  </Col>
                  <Col md="3" className="text-right">
                    <span>Preview</span>
                    <label className="custom-toggle mr-1">
                      <input type="checkbox" onChange={(evt) => {
                        if(!evt.target.checked) {
                          setType('iframe');

                          // Set display
                          if(portfolio.artwork.selected) {
                            // Pass selected artwork
                            setUrl(`https://localhost:1234?context=${encodeContext(portfolio.artwork.selected.artwork)}`);
                          } else {
                            // Default
                            setUrl("https://localhost:1234");
                          }
                        } else {
                          setType('image');

                          // Set URL (if selected)
                          if(portfolio.artwork.selected) {
                              setUrl(`https://picasso.arthaus.cloud/cache/artworks/${portfolio.artwork.selected.artwork.file_name}.webp`);
                          }
                        }
                      }}/>
                      <span
                        className="custom-toggle-slider rounded-circle"
                      />
                    </label>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                        setType('iframe');

                        // Set display
                        if(portfolio.artwork.selected) {
                          // Pass selected artwork
                          setUrl(`https://localhost:1234?context=${encodeContext(portfolio.artwork.selected.artwork)}`);
                        } else {
                          // Default
                          setUrl("https://localhost:1234");
                        }
                      }}
                    >
                      Display Card
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    { portfolio.artwork.selected ?
                      <DisplayCard />
                      :
                      <Row>
                        <Col md="12">
                          <Card className="mt-4">
                              <CardBody>
                                <h2 className="text-center">Select an Artwork to Display</h2>
                              </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    }
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <MobileSimulator url={url} type={type} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedVirtualGallery = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(VirtualGallery);

export default ConnectedVirtualGallery;
