/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

// react library for routing
import { useParams } from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Rich Text Editor (Quill)
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardImg,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Load config
import config from "config";

// Firebase
import { firestore } from "./../../../../firebase";

// Auth actions
import {
  SET_ACTIVE_ARTWORK,
  SET_ACTIVE_EDITION
} from 'store/actions';

// core components
import PortfolioHeader from "components/Headers/PortfolioHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function ViewEdition({ profile, portfolio, dispatch }) {
  // Get context
  const { id } = useParams();
  console.log("View Edition: ", id);

  // States (Navigation)
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  // Get history
  const history = useHistory();

  // Check active edition
  if(portfolio && !portfolio.edition.selected) {
    // Query for ID
    firestore.collection("editions").doc(id)
      .onSnapshot((doc) => {
        // Parse data
        let editionData = doc.data();
        console.log("Edition Data: ", editionData);

        // Update local store
        dispatch({
          type: SET_ACTIVE_EDITION,
          payload: editionData
        });
      });
  }

  // Content Editor
  const [commentary, setCommentary] = useState('');
  const onCommentaryUpdated = (newValue) => {
    if (newValue !== commentary) {
      setCommentary(newValue);
    }
  };

  // View gallery
  const viewGallery = (edition) => {
    // Query for ID
    firestore.collection("artworks").doc(edition.artwork.file_name)
      .onSnapshot((doc) => {
        // Parse data
        let artworkData = doc.data();
        console.log("Artwork Data: ", artworkData);

        // Update local store
        dispatch({
          type: SET_ACTIVE_ARTWORK,
          payload: artworkData
        });

        // Got to review page
        history.push(`/admin/gallery`);
      });
  }

  return (
    <>
      <PortfolioHeader
        title="Edition Title"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        collapsed={true}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Card>
              <CardImg
                alt="..."
                src={`https://picasso.arthaus.cloud/cache/artworks/${(portfolio.edition.selected && portfolio.edition.selected.artwork) ? portfolio.edition.selected.artwork.file_name : ''}.webp`}
                top
              />

              <CardBody>
                <CardTitle className="h2 mb-0">
                  {`${(portfolio.edition.selected && portfolio.edition.selected.details) ? portfolio.edition.selected.details.display : ''}`}
                </CardTitle>
                <small className="text-muted">
                  published on Oct 29th, 2021
                </small>

                <Row>

                  <Col md="12">

                    <Button block color="success" size="lg" type="button" className="mt-4"
                      onClick={() => {
                        // TBD
                      }}>
                      Publish Edition
                    </Button>
                    { portfolio.edition.selected &&
                      <Button block color="secondary" size="lg" type="button" className="ml-0"
                        onClick={() => {
                          // Select artwork and load gallery preview
                          viewGallery(portfolio.edition.selected);
                        }}>
                        View Gallery
                      </Button>
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Edition Details</h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { setActiveTab('1'); }}
                    >
                      Content
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { setActiveTab('2'); }}
                    >
                      Artifact
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { setActiveTab('2'); }}
                    >
                      Gallery
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row className="mt-4">
                      <Col sm="12">
                        <div>Edition Content</div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <div>Physical Artifact Details</div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <div>Virtual Gallery</div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewEdition = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(ViewEdition);

export default ConnectedViewEdition;
