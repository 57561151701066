/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// react library for routing
import { useLocation, useHistory, Route, Switch, Redirect } from "react-router-dom";

// API (Axon)
import Arthaus from '@axon/arthaus';

// Config
import config from "config";

// Authentication
import { auth, firestore } from "./../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// App context
import routes from "routes.js";

// Actions
import {
  INITIALIZE_PROFILE,
  INITIALIZE_PORTFOLIO,
  INITIALIZE_STYLE,
  SET_ARTWORKS,
  SET_COLLECTIONS,
  SET_EDITIONS
} from "store/actions.js";

function Admin({ profile, portfolio, style, dispatch }) {
  // States UX
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const mainContentRef = React.useRef(null);

  // States (Auth)
  const [user, loading, error] = useAuthState(auth);

  // Navigation
  const location = useLocation();
  const history = useHistory();

  // Get profile
  useEffect(() => {
    if (loading) return;
    if (user) {
      console.log("User Loaded: ", user);

      // Load profile
      loadProfile();
    } else {
      // Send back to login
      history.push("/auth/login");
    }
  }, [user, loading]);

  const loadProfile = async () => {
    const profilesRef = firestore.collection("profiles");
    const profile = await profilesRef.doc(user.uid).get();
    if(profile.exists) {
      // Parse profile
      const profileData = profile.data();
      console.log("Profile Exists: ", profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });
    } else {
      alert("ERROR: Profile does not exist.");
    }
  }

  // Activate artist profile
  React.useEffect(() => {
    // Sync if not already active
    if(profile && profile.initialized) {
      // Update portfolio reference - init
      // (async () => {
      //   // Get portfolio data
      //   const portfoliosRef = firestore.collection("portfolios");
      //   const portfolio = await portfoliosRef.doc(profile.user.uid).get();
      //   if(portfolio.exists) {
      //     // Parse portfolio
      //     const portfolioConfig = portfolio.data();
      //
      //     // Dispatch to update
      //     dispatch({
      //       type: INITIALIZE_PORTFOLIO,
      //       payload: portfolioConfig
      //     })
      //   }
      // })();

      // Get portfolio and listen for updates
      firestore.collection("portfolios").doc(user.uid)
        .onSnapshot((doc) => {
          if(doc.exists) {
            // Parse portfolio
            const portfolioConfig = doc.data();

            // Dispatch to update
            dispatch({
              type: INITIALIZE_PORTFOLIO,
              payload: portfolioConfig
            });

            // Setup initial style
            if(style && !style.initialized && portfolioConfig && portfolioConfig.style) {
              dispatch({
                type: INITIALIZE_STYLE,
                payload: {
                  theme: {
                    name: portfolioConfig.style.name ? portfolioConfig.style.name : "",
                    description: portfolioConfig.style.explanation ? portfolioConfig.style.explanation : ""
                  },
                  palette: {
                    current: portfolioConfig.style.palette,
                    updated: portfolioConfig.style.palette
                  }
                }
              });
            }
          }
        });

      // Get initial artworks
      Arthaus.api.artworks.find({
        artistId: profile.user.artistId,
        take: 10,
        skip: 0
      }, function(artworks, facets) {
        // update artworks
        dispatch({
          type: SET_ARTWORKS,
          payload: {
            artworks: artworks,
            facets: facets
          }
        });
      });

      // Collections - firestore update
      firestore.collection("collections")
        .where("artist.id", "==", profile.user.artistId).limit(10)
        .onSnapshot((querySnapshot) => {
          // Iterate and append to list
          let collections = [];
          querySnapshot.forEach((doc) => { collections.push(doc.data()); });

          // Update ETL
          dispatch({
            type: SET_COLLECTIONS,
            payload: collections
          });
        });

      // Editions - get editions
      Arthaus.api.editions.find({
        artistId: profile.user.artistId,
        take: 10,
        skip: 0
      }, function(editions, facets) {
        // update artworks
        dispatch({
          type: SET_EDITIONS,
          payload: {
            editions: editions,
            facets: facets
          }
        });
      });
    }
  }, [profile]);

  // Setup routs
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/brand/logo.svg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          profile={profile}
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

// Connect to store
const ConnectedAdmin = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio,
  style: state.style
}))(Admin);


export default ConnectedAdmin;
