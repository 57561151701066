// Setup config export
const config = {
    api: {
      url: 'https://api.arthaus.cloud/v1/graphql'
    },
    portfolio: {
      palette: {
        url: "http://localhost:5001/arthaus-us/us-central1/portfolio-palette/palette"
      },
      content: {
        url: "http://localhost:5001/arthaus-us/us-central1/portfolio-content/content"
      }
    },
    search: {
      account: {
        applicationId: "G9NMXP1524",
        apiKey: "2cee0c4f2e7e8fa96558f69a935d586d"
      },
      index: {
        artworks: {
          id: "artworks"
        },
        editions: {
          id: "editions"
        }
      }
    }
};

export default config;
