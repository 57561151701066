import firebase from "firebase";

// Import from config
import { version, accountId, env, build, firebaseConfig, reduxFirebase } from "./config";

// Initialize
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Get database and firestore
const database = firebase.database();
const firestore = firebase.firestore();

// Array operations
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

// Export results
export {
  version,
  accountId,
  env,
  build,
  firebaseConfig,
  reduxFirebase,
  auth,
  database,
  firestore,
  googleAuthProvider,
  arrayUnion,
  arrayRemove
};
