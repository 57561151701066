/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// debounce
import _debounce from 'lodash/debounce';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Firestore
import { firestore } from "./../../../../firebase";

// API (Axon)
import Arthaus from '@axon/arthaus';

// Initial Card Data
const initialFormState = {
  instagram: {
    url: ''
  },
  twitter: {
    url: ''
  },
  tiktok: {
    url: ''
  },
  facebook: {
    url: ''
  },
  youtube: {
    url: ''
  },
  snapchat: {
    url: ''
  },
  twitch: {
    url: ''
  }
};

// Mobile Simulator
function SocialCard({ profile, portfolio, dispatch }) {
  // Local States
  const [formData, setFormData] = useState(initialFormState);

  // Set initial seed values
  useEffect(() => {
    if(portfolio && portfolio.config) {
      // Set seed bio
      setFormData(portfolio.config.apps.profile.social);
    }
  }, [portfolio]);

  // Handle update
  const handleUpdate = async () => {
    console.log("Updated Social Links: ", formData);

    // Store updated links
    if(profile && profile.initialized) {
        // Save to firestore
        const portfoliosRef = await firestore.collection("portfolios").doc(profile.user.uid);
        await portfoliosRef.set({
          apps: {
            profile: {
              social: formData
            }
          }
        }, { merge: true });
    }
  }


  return (
    <>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          Instagram
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.instagram.url}
            onChange={e => setFormData({ ...formData, 'instagram': { ...formData.instagram, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          Twitter
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.twitter.url}
            onChange={e => setFormData({ ...formData, 'twitter': { ...formData.twitter, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          TikTok
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.tiktok.url}
            onChange={e => setFormData({ ...formData, 'tiktok': { ...formData.tiktok, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          Facebook
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.facebook.url}
            onChange={e => setFormData({ ...formData, 'facebook': { ...formData.facebook, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          YouTube
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.youtube.url}
            onChange={e => setFormData({ ...formData, 'youtube': { ...formData.youtube, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          Snapchat
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.snapchat.url}
            onChange={e => setFormData({ ...formData, 'snapchat': { ...formData.snapchat, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="1"></Col>
        <Label
          className="form-control-label"
          htmlFor="example-text-input"
          md="2"
        >
          Twitch
        </Label>
        <Col md="7">
          <Input
            id="input-placeholder-link-url"
            type="text"
            value={formData.twitch.url}
            onChange={e => setFormData({ ...formData, 'twitch': { ...formData.twitch, 'url': e.target.value}})}
          />
        </Col>
        <Col md="1">
          <Button color="secondary" size="md" onClick={handleUpdate}>
            <span className="btn-inner--icon mr-2">
              <i className="ni ni-cloud-upload-96" />
            </span>
          </Button>
        </Col>
      </Row>
    </>
  )
}

// Connect to store
const ConnectedSocialCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(SocialCard);

export default ConnectedSocialCard;
