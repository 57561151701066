/*
    Artworks API
 */

// Imports (external)
import axios from 'axios';
import _ from 'lodash';

// Import Configuration
import config from '../config';

// Orders
const Portfolio = {
  // Palette endpoint
  palette: {
    // Generate palette from query
    generate: function(params, callback) {
      console.log("Calling Endoint: Portfolio-Palette generate", params);

      // Construct payload
      const data = {
        portfolio: {
          id: "k5UqWnhdyWOgQvcjCjuQYfYDBtE2"
        },
        data: {
          name: params.query ? params.query : "modern"
        }
      };

      // Make syncrhonous call
      fetch(`${config.portfolio.palette.url}/generate`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((payload) => {
        console.log(payload);
        console.log("Results: ", payload.data.formatted.data.palette);

        // Check respnose
        if(payload.status == "SUCCESS") {
          console.log(payload.status);

          // Etract results
          let newPalette = (payload.data && payload.data.formatted) ? payload.data.formatted.data.palette : null;
          console.log(" --> New Palette: ", newPalette);
          callback(newPalette);
        } else {
          callback(null);
        }
      })
      .catch((error) => {
        console.log(error);

        callback(null);
      });
    }
  },

  // Content endpoint
  content: {
    // Generate biography
    biography: function(params, callback) {
      console.log("Calling Endoint: Portfolio-Content biography", params);

      // Construct payload
      const data = {
        portfolio: {
          id: "k5UqWnhdyWOgQvcjCjuQYfYDBtE2"
        },
        data: {
          biography: params.biography ? params.biography : "",
          themes: params.themes ? params.themes : []
        }
      };

      // Make syncrhonous call
      fetch(`${config.portfolio.content.url}/biography`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((payload) => {
        // Check respnose
        if(payload.status == "SUCCESS") {
          // Etract results
          let enhancedBiography = (payload.data && payload.data.formatted) ? payload.data.formatted.data.biography : "";
          console.log(" --> Enhanced Biography: ", enhancedBiography);
          callback(enhancedBiography);
        } else {
          callback("");
        }
      })
      .catch((error) => {
        console.log(error);

        callback(null);
      });
    },

    // Generate headline
    headline: function(params, callback) {
      console.log("Calling Endoint: Portfolio-Content headline", params);

      // Construct payload
      const data = {
        portfolio: {
          id: "k5UqWnhdyWOgQvcjCjuQYfYDBtE2"
        },
        data: {
          biography: params.biography ? params.biography : "",
          themes: params.themes ? params.themes : [],
          description: params.description ? params.description : ""
        }
      };

      // Make syncrhonous call
      fetch(`${config.portfolio.content.url}/headline`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response) => response.json())
      .then((payload) => {
        // Check respnose
        if(payload.status == "SUCCESS") {
          // Etract results
          let headline = (payload.data && payload.data.formatted) ? payload.data.formatted.data.headline : "";
          console.log(" --> Artist Headline: ", headline);
          callback(headline);
        } else {
          callback("");
        }
      })
      .catch((error) => {
        console.log(error);

        callback(null);
      });
    }
  }
}

// Export module
export default Portfolio;
