/*
 *  Edit Link: Featured
 */

// Imports
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// UI Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// Search Bar
import Search from "components/Search/Search.js";

// Load config
import config from "config";

// Firebase
import { firestore } from "./../../../../../../firebase";

// Initial Card Data
const initialFormState = {
  title: '',
  description: '',
  action: {
    label: '',
    link: {
      url: ''
    }
  },
  artworks: []
};

// List container
export const EditFeatured = ({ id, data, onUpdate }) => {
  // States
  const [formData, setFormData] = useState(data ? data : initialFormState);

  // Handle artwork selection
  const selectArtwork = (artwork) => {
    // Lookup product
    const documentId = artwork.file_name ? artwork.file_name : artwork.id;
    firestore.collection("artworks").doc(documentId)
      .onSnapshot((doc) => {
        // Parse data
        let artworkData = doc.data();
        console.log("Selected Artwork Data: ", artworkData);

        // Get loaded artworks
        let artworks = formData.artworks;

        // Create new artowkr
        let newArtwork = {
          position: artworks.length,
          id: (artworkData.artwork) ? artworkData.artwork.file_name : artwork.id,
          title: (artworkData.artwork) ? artworkData.artwork.name : artwork.name,
          description: (artworkData.artwork) ? artworkData.artwork.description : '',
          link: {
            url: (artworkData.products && artworkData.products.framed) ? `https://shop.myarthaus.com/products/${artworkData.products.framed.handle}` : ''
          },
          image: {
            url: `https://picasso.arthaus.cloud/cache/artworks/${(artworkData.artwork) ? artworkData.artwork.file_name : artwork.id}.webp?quality=low`
          }
        }

        // Add to artworks
        artworks.push(newArtwork);

        // Update
        setFormData({...formData, 'artworks': artworks});
      });
  }

  // Remove artwork
  const removeArtwork = (artwork) => {
    // Get loaded artworks
    let newArtworks = [];

    // Iterate over artworks - remove matching
    for(var i = 0; i < formData.artworks.length; i++) {
      // Check artwork
      if(formData.artworks[i].id != artwork.id) {
        // Add to artworks
        newArtworks.push(formData.artworks[i]);
      }
    }

    // Update position
    for(var i = 0; i < newArtworks.length; i++) {
      newArtworks.position = i;
    }

    // Update
    setFormData({...formData, 'artworks': newArtworks});
  }

  // Update description
  const updateDescription = (artwork, description) => {
    console.log(" - updating description");
    // Update
    let updatedArtworks = formData.artworks;

    // Iteraet over artworks - update
    for(var i = 0; i < updatedArtworks.length; i++) {
      if(updatedArtworks[i].id == artwork.id) {
        // Set description
        updatedArtworks[i].description = description;
      }
    }

    // Update
    setFormData({...formData, 'artworks': updatedArtworks});
  }

  // Return
  return(
    <>
      <div className="modal-body">
        <Form>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-title"
                  >
                    Title
                  </label>
                  <Input
                    id="input-title"
                    type="text"
                    value={formData.title}
                    onChange={e => setFormData({ ...formData, 'title': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-description"
                  >
                    Description
                  </label>
                  <Input
                    id="input-description"
                    type="text"
                    value={formData.description ? formData.description : ''}
                    onChange={e => setFormData({ ...formData, 'description': e.target.value})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-link-url"
                  >
                    Portfolio Action
                  </label>
                  <Input
                    id="input-link-url"
                    type="text"
                    value={formData.action.label}
                    onChange={e => setFormData({ ...formData, 'action': { ...formData.action, 'label': e.target.value }})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-link-url"
                  >
                    Portfolio URL
                  </label>
                  <Input
                    id="input-link-url"
                    type="text"
                    value={formData.action.link.url}
                    onChange={e => setFormData({ ...formData, 'action': { ...formData.action, 'link': { url: e.target.value }}})}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-link-url"
                  >
                    Featured Artworks
                  </label>
                  <Row>
                    <Col lg="12">
                      <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
                        <CardBody>
                          <Row>
                            <Col xl="12">
                              <Search index="artworks" onSelect={selectArtwork} />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    { formData.artworks.map((artwork, idx) => (
                      <Col lg="12" key={idx}>
                        <Card style={{
                          height: 150,
                          backgroundImage: `url(${artwork.image.url})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}>
                          <CardBody>
                            <CardTitle style={{
                              color: '#F5F5F5'
                            }}>
                              { artwork.title }
                            </CardTitle>
                            <Input
                              id="input-artwork-description"
                              type="text"
                              defaultValue={artwork.description ? artwork.description : ''}
                              onChange={e => updateDescription(artwork, e.target.value)}
                              style={{
                                position: 'absolute',
                                bottom: 20,
                                left: 20,
                                width: 'calc(100% - 40px)'
                              }}
                            />
                            <Button color="danger" size="md"
                              onClick={() => removeArtwork(artwork)} style={{
                              position: 'absolute',
                              top: 20,
                              right: 20
                            }}>
                              <span className="btn-inner--text">Remove</span>
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          onClick={() => {
            // Call Update
            onUpdate(id, formData);
          }}
          size="xl"
        >
          Update Link
        </Button>
      </div>
    </>
  )
}

/*

<Row>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-link-url"
      >
        Portfolio Action
      </label>
      <Input
        id="input-link-url"
        type="text"
        value={formData.action.label}
        onChange={e => setFormData({ ...formData, 'action': { label: e.target.value }})}
      />
    </FormGroup>
  </Col>
</Row>
<Row>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-link-url"
      >
        Portfolio URL
      </label>
      <Input
        id="input-link-url"
        type="text"
        value={formData.action.link.url}
        onChange={e => setFormData({ ...formData, 'action': { link: { url: e.target.value }}})}
      />
    </FormGroup>
  </Col>
</Row>
<Row>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-link-url"
      >
        Artworks
      </label>
      <Row>
        <Col lg="12">
          <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
            <CardBody>
              <Row>
                <Col xl="12">
                  <Search index="artworks" onSelect={selectArtwork} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        { formData.artworks.map((artwork, idx) => (
          <Col lg="12" key={idx}>
            <Card style={{
              height: 150,
              backgroundImage: `url(${artwork.image.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
              <CardBody>
                <CardTitle style={{
                  color: '#F5F5F5'
                }}>
                  { artwork.title }
                </CardTitle>
                <Input
                  id="input-artwork-description"
                  type="text"
                  value={artwork.description ? artwork.description : ''}
                  onChange={e => updateDescription(artwork, e.target.value)}
                  style={{
                    position: 'absolute',
                    bottom: 20,
                    left: 20,
                    width: 'calc(100% - 40px)'
                  }}
                />
                <Button color="danger" size="md"
                  onClick={() => removeArtwork(artwork)} style={{
                  position: 'absolute',
                  top: 20,
                  right: 20
                }}>
                  <span className="btn-inner--text">Remove</span>
                </Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </FormGroup>

  </Col>
</Row>


*/
