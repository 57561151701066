/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Search Bar
import Search from "components/Search/Search.js";

// Firestore
import { firestore } from "./../../../../firebase";

// Mobile Simulator
function LandingPageCard({ profile, portfolio, dispatch }) {
  // Handle artwork selection
  const selectArtwork = async (artwork) => {
    // Lookup product
    const documentId = artwork.file_name ? artwork.file_name : artwork.id;
    firestore.collection("artworks").doc(documentId)
      .onSnapshot((doc) => {
        // Parse data
        let artworkData = doc.data();
        console.log("Selected Artwork Data: ", artworkData);

        // Create new artowkr
        let newArtwork = {
          id: (artworkData.artwork) ? artworkData.artwork.file_name : artwork.id,
          title: (artworkData.artwork) ? artworkData.artwork.name : artwork.name,
          image: {
            url: `https://picasso.arthaus.cloud/cache/artworks/${(artworkData.artwork) ? artworkData.artwork.file_name : artwork.id}.webp`
          }
        }

        console.log(" - updating shop placeholder background: ", newArtwork);

        // Update portfolio
        if(profile && profile.initialized) {
            // Save to firestore
            firestore.collection("portfolios").doc(profile.user.uid).set({
              apps: {
                shop: {
                  placeholder: {
                    background: newArtwork
                  }
                }
              }
            }, { merge: true }).then((updatedDoc) => {
              console.log(" - Updated: ", updatedDoc);
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg="12" className="mt-4">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-placeholder-link-url"
            >
              Background Artwork
            </label>
            <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <Search index="artworks" onSelect={selectArtwork} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </FormGroup>
        </Col>
        <Col lg="12">
          <Card style={{
            height: 150,
            backgroundImage: `url(${(portfolio && portfolio.config && portfolio.config.apps.shop.placeholder) ? `${portfolio.config.apps.shop.placeholder.background.image.url}` : ''}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
            <CardBody>
              <CardTitle style={{
                color: '#F5F5F5'
              }}>
                { (portfolio && portfolio.config && portfolio.config.apps.shop.placeholder) ? portfolio.config.apps.shop.placeholder.background.title : ''}
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="input-placeholder-link-url"
            >
              Destination URL (Post Sign-Up)
            </label>
            <Input
              id="input-placeholder-link-url"
              type="text"
              value={(portfolio && portfolio.config && portfolio.config.apps.shop.placeholder) ? portfolio.config.apps.shop.placeholder.link.url : ''}
              disabled
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

// Connect to store
const ConnectedLandingPageCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(LandingPageCard);

export default ConnectedLandingPageCard;
