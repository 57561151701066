/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Load config
import config from "config";

// Firebase
import { firestore } from "./../../../firebase";

// Auth actions
import {
  SET_ACTIVE_COLLECTION
} from 'store/actions';

// core components
import PortfolioHeader from "components/Headers/PortfolioHeader.js";

function Collections({ profile, portfolio, dispatch }) {
  // States (UX)
  const [location, setlocation] = React.useState(false);

  // Get history
  const history = useHistory();

  // Handle artwork selection
  const selectCollection = (collection) => {
    // Query for ID
    firestore.collection("collections").doc(collection.id)
      .onSnapshot((doc) => {
        // Parse data
        let collectionData = doc.data();
        console.log("Collection Data: ", collectionData);

        // Update local store
        dispatch({
          type: SET_ACTIVE_COLLECTION,
          payload: collectionData
        });

        // Got to review page
        history.push(`/admin/collections/view/${collection.id}`);
      });
  }

  return (
    <>
      <PortfolioHeader
        title="My Collections"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="3">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Identified Themes</h5>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  { portfolio.themes.map((keyword, index) => (
                    <ListGroupItem className="px-0" key={index}>
                      <Row className="align-items-center">
                        <div className="col">
                          <h5>{keyword.display}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="primary"
                            max="100"
                            value={keyword.value}
                          />
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="9">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Published Collections</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Collection
                    </th>
                    <th className="sort" data-sort="status" scope="col">
                      Artworks
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { portfolio.collections.map((collection, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="name mb-0 text-sm">
                              {collection.details.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td className="text-center">
                        {collection.artworks.length}
                      </td>
                      <td className="text-right">
                        <Button color="primary" size="md" type="button"
                          style={{
                            width: '100%'
                          }}
                          onClick={() => selectCollection(collection) }
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedCollections = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(Collections);

export default ConnectedCollections;
