/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Copy to Clipboard
import copy from 'copy-to-clipboard';

// core components
import ShowcaseHeader from "components/Headers/ShowcaseHeader.js";

// showcase components
import PaletteConfigurator from "components/Style/Palette.js";
import MobileSimulator from "components/Simulator/Mobile.js";

// Tabs
import LandingPageCard from "./tabs/LandingPageCard";

function PersonalShop({ profile, portfolio, dispatch }) {
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  // Site Simulator
  const [url, setUrl] = React.useState("https://my.arthaus.site/kaethe-butcher");

  return (
    <>
      <ShowcaseHeader
        title="Personal Shop"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="7">
            <Card className="bg-gradient-primary border-0"
              style={{
                cursor: 'pointer',
                height: 95
              }}
            >
              <CardBody>
                <Row>
                  <Col xl="9">
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: location,
                        })}
                      >
                        <Input
                          placeholder={`https://my.arthaus.site/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`}
                          type="text"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-link" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="3">
                    <Button size="md" color="default" onClick={() => {
                      // copy to clipboard
                      copy(`https://my.arthaus.site/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`);
                    }}>
                      <span className="btn-inner--icon mr-2">
                        <i className="ni ni-single-copy-04" />
                      </span>
                      <span className="btn-inner--text">Copy Link</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Configure Your Shop</h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        setActiveTab('1');
                        setUrl(`https://my.arthaus.site/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`)
                      }}
                    >
                      Landing Page
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <LandingPageCard />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <MobileSimulator url={`https://my.arthaus.site/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedPersonalShop = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(PersonalShop);

export default ConnectedPersonalShop;
