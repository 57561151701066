/*
 *   DisplayCard
 */

// React imports
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// debounce
import _debounce from 'lodash/debounce';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";

// Firestore
import { firestore } from "./../../../../firebase";

import PaletteConfigurator from "components/Style/Palette.js";

// API (Axon)
import Arthaus from '@axon/arthaus';

// Mobile Simulator
function StyleCard({ profile, portfolio, style, dispatch }) {
  // States (UX)
  const [editing, setEditing] = React.useState(style.editing);
  const [query, setQuery] = React.useState("");
  //const [editPalette, setEditPalette] = React.useState(null);
  const [generating, setGenerating] = React.useState(false);

  // Helper - debounce
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn(query) {
    console.log(" --> Query: ", query);

    // Set generating
    setGenerating(true);

    // Call generate endpoint
    Arthaus.api.portfolio.palette.generate({
      query: query
    }, function(palette) {
      console.log(" --> Palette: ", palette);

      // Update edit palette (if successful)
      if(palette) {
        // Set edit palette
        //setEditPalette(palette);
        setEditing(true);
      }

      // Finish generating
      setGenerating(false);
    });
  }

  // Create palette lookup
  const generatePalette = (event) => {
    // Update
    setQuery(event.target.value);
    debounceFn(event.target.value);
  }

  // Save updated palette
  const updatePalette = async (palette) => {
    console.log("Saving Palette: ", palette);

    // Update portfolios
    const portfoliosRef = firestore.collection("portfolios").doc(profile.user.uid);
    const updatedPortfolio = await portfoliosRef.set(
      {
        style: {
          palette: palette
        }
      },
      { merge: true }
    );
    console.log(" -- Updated portfolio: ", updatedPortfolio);

    // Mark as not editng
    setEditing(false);
  }


  return (
    <Row>
      <Col md="12">
        <PaletteConfigurator
          updatePalette={updatePalette}
        />
      </Col>
    </Row>
  )
}

// Connect to store
const ConnectedStyleCard = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio,
  style: state.style
}))(StyleCard);

export default ConnectedStyleCard;
