export const version = "1.0.0";

export const accountId = "";

export const env = "prod";

export const firebaseConfig = {
  apiKey: "AIzaSyCzy2jmjEppUMa3nB4Yaolv4rhWShjM3ZM",
  authDomain: "arthaus-us.firebaseapp.com",
  databaseURL: "https://arthaus-us-default-rtdb.firebaseio.com",
  projectId: "arthaus-us",
  storageBucket: "arthaus-us.appspot.com",
  messagingSenderId: "156671318330",
  appId: "1:156671318330:web:4aa8076b3f25bb31925803",
  measurementId: "G-6PBLT8445V"
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "http://localhost:5001/axon-platform/us-central1",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://localhost:9000",
};

export const configs = {
  baseUrl: "https://axon-config.ngrok.io",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
