/*
    Artworks API
 */

// Imports (external)
import { request, gql } from 'graphql-request';
import _ from 'lodash';

// Import Configuration
import config from '../config';

// Import Algolio
import algoliasearch from 'algoliasearch';

// Initialize search index
const client = algoliasearch(config.search.account.applicationId, config.search.account.apiKey);
const index = client.initIndex(config.search.index.editions.id);

// Orders
const Editions = {
  // Find artworks (with query)
  find: function(params, callback) {
    // Execute query
    index.search('', {
      filters: `artist.id:${params.artistId}`,
      page: params.index,
      hitsPerPage: params.take,
      facets: ['artwork.name']
    }).then(({ hits, facets }) => {
      console.log("Search: ", hits, facets);

      // Extract artworks from records
      const editions = hits;
      console.log(" -> ", editions);

      // Return data
      callback(editions, facets);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback([], []);
    });
  },

  // Search artworks (with query)
  search: function(params, callback) {
    console.log(params);

    // Execute query
    index.search(params.query, {
      filters: `artist.id:${params.artistId}`,
      page: params.index,
      hitsPerPage: params.take
    }).then(({ hits }) => {
      console.log("Search: ", hits);

      // Extract artworks from records
      const editions = hits;
      console.log(" -> ", editions);

      // Return data
      callback(editions);
    }).catch((error) => {
      console.error(error);

      // Return empty array
      callback([]);
    });
  }
}

// Export module
export default Editions;
