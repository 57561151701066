import { combineReducers } from 'redux';

// Core
import profileReducer from './profile';
import portfolioReducer from './portfolio';
import styleReducer from './style';

// Apps
import galleryReducer from './gallery';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    profile: profileReducer,
    portfolio: portfolioReducer,
    style: styleReducer,
    gallery: galleryReducer
});

export default reducer;
