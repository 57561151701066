
const config = {
  "account": {
    "contact": "admin@arthaus.cloud",
    "id": "arthaus",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/logo.svg",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/arthaus/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://arthaus.cloud",
      "name": "Arthaus International, Inc."
    }
  },
  "theme": {
    "palette": {
      "color-1": "#1E1E1E",
      "color-2": "#353B40",
      "color-3": "#353B40",
      "color-4": "#283D62",
      "color-5": "#C7B078"
    }
  },
  "portfolio": {
    "palette": {
      "url": "http://localhost:5001/arthaus-us/us-central1/portfolio-palette/palette/"
    }
  },
  "media": {
    "cache": {
      "url": "https://picasso.arthaus.cloud/cache"
    },
    "compare": {
      "url": "https://picasso.arthaus.cloud/image/compare"
    }
  },
  "ams": {
    "url": "https://artists.myarthaus.com"
  },
  "search": {
    "account": {
      "applicationId": "G9NMXP1524",
      "apiKey": "2cee0c4f2e7e8fa96558f69a935d586d"
    },
    "index": {
      "artworks": {
        "id": "artworks"
      }
    }
  }
}

export default config;
