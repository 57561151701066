// action - state management
import * as actionTypes from '../actions';
import _ from 'lodash';

const initialState = {
    // Configuration
    config: null,
    render: null,

    // Artworks
    artworks: [],
    concepts: [],
    artwork: {
      selected: null,
      labels: [],
      listings: [],
      similar: [],
    },

    // Collections
    collections: [],
    themes: [],
    collection: {
      selected: null,
      keywords: [],
      artworks: []
    },

    // Editions
    editions: [],
    editionArtworks: [],
    edition: {
      selected: null
    }
};

// Block List
const conceptStopWords = ['Art', 'Painting', 'Illustration'];
const themeStopWords = ['Art', 'Painting', 'Illustration'];

// ===========================|| PORTFOLIO REDUCER ||=========================== //

const portfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_PORTFOLIO: {
          console.log("Initializing Portfolio: ", action.payload);

          // Update state
          return {
              ...state,
              config: action.payload
          };
        }
        case actionTypes.RENDER_PORTFOLIO: {
          console.log("Rendering Portfolio: ", action.payload);

          // Update state
          return {
              ...state,
              render: Date.now()
          };
        }
        case actionTypes.SET_ARTWORKS: {
          console.log("Setting Artworks: ", action.payload);

          // Extract concepts (Lael Descriptions)
          let concepts = [];
          _.forOwn(action.payload.facets['enrichments.labels.description'], function(count, name) {
            // Add to themes
            if(!conceptStopWords.includes(name)) {
              concepts.push({
                name: name,
                count: count
              });
            }
          });
          console.log("Concepts: ", concepts);

          // Update state
          return {
              ...state,
              artworks: action.payload.artworks,
              concepts: concepts.slice(0, 10)
          };
        }
        case actionTypes.SET_ACTIVE_ARTWORK: {
          console.log("Setting Active Artwork: ", action.payload);

          // Grab context
          const { artwork, image, enrichments } = action.payload;

          // Set ranked labels
          const sortedLabels = (enrichments && enrichments.labels && enrichments.labels.length > 0) ?_.sortBy(enrichments.labels, ['score', 'topicality']).reverse() : [];

          // Identify active listings
          let listings = [];
          // if(enrichments.knowledge && enrichments.knowledge.tags && enrichments.knowledge.tags.length > 0) {
          //   // Find product listings
          //   const webResults = _.find(enrichments.knowledge.tags[0]['actions'], { 'actionType': 'PagesIncluding' });
          //   console.log(" - Web Search Results: ", webResults);
          //
          //   // PArse results
          //   // if(visualSearchResults && visualSearchResults.data && visualSearchResults.data.value && visualSearchResults.data.value.length > 0) {
          //   //   // Get first 10 results
          //   //   for(var i = 0; i < visualSearchResults.data.value.slice(0, 10).length; i++) {
          //   //     console.log(visualSearchResults.data.value[i]);
          //   //   }
          //   // }
          // }

          // Update state
          return {
              ...state,
              artwork: {
                selected: action.payload,
                labels: sortedLabels
              }
          };
        }
        case actionTypes.SET_COLLECTIONS: {
          console.log("Setting Collections: ", action.payload);

          // Extract themes (Lael Descriptions)
          let themeMap = {};
          _.forEach(action.payload, function(collection) {
            // Iterate over keywords
            _.forEach(collection.keywords, function(keyword) {
              // Add to map
              if(!themeStopWords.includes(keyword.display)) {
                themeMap[keyword.id] = keyword;
              }
            });
          });

          // Extract themes
          let themes = _.values(themeMap);

          // Sort
          themes = _.orderBy(themes, 'value', 'desc')
          console.log(" - Themes: ", themes);

          // Update state
          return {
              ...state,
              collections: action.payload,
              themes: themes.slice(0, 10)
          };
        }
        case actionTypes.SET_ACTIVE_COLLECTION: {
          console.log("Setting Active Collection: ", action.payload);

          // Update state
          return {
              ...state,
              collection: {
                selected: action.payload,
                keywords: (action.payload.keywords) ? action.payload.keywords : [],
                artworks: (action.payload.artworks) ? action.payload.artworks : []
              }
          };
        }
        case actionTypes.SET_EDITIONS: {
          console.log("Setting Editions: ", action.payload);

          // Extract themes (Lael Descriptions)
          let editionArtworks = [];
          _.forOwn(action.payload.facets['artwork.name'], function(count, name) {
            // Add to editionArtworks
            editionArtworks.push({
              name: name,
              count: count
            });
          });
          console.log("Edition Artworks: ", editionArtworks);

          // Update state
          return {
              ...state,
              editions: action.payload.editions,
              editionArtworks: editionArtworks.slice(0, 10)
          };
        }
        case actionTypes.SET_ACTIVE_EDITION: {
          console.log("Setting Active Edition: ", action.payload);

          // Update state
          return {
              ...state,
              edition: {
                selected: action.payload
              }
          };
        }
        default:
            return state;
    }
};

export default portfolioReducer;
