/*
 *  Arthaus SDK
 *  ---------------
 */

// Import API endpoints
import Portfolio from './portfolio';
import Artists from './artists';
import Artworks from './artworks';
import Editions from './editions';

// Export object
const Arthaus = {
  namespace: "@arthaus",

  api: {
    portfolio: Portfolio,
    artists: Artists,
    artworks: Artworks,
    editions: Editions
  }
};

// Exports
export default Arthaus;
