// Imports
import update from 'immutability-helper';
import { useCallback, useState, useRef, useEffect } from 'react';
import { Card } from './Card.js';
import _ from 'lodash';

// Style
const style = {
  width: '100%'
}

// List container
export const Container = ({ links, onReorder, onUpdate }) => {
  {
    // States
    const [cards, setCards] = useState(links);
    const [moved, setMovement] = useState(false);
    const didMount = useRef(false);

    // Wrap udpate
    const updateLinks = _.debounce(() => {
      onReorder(cards);
    }, 250, { 'maxWait': 5000 });

    // Don't fire on first render
    if (!didMount.current) {
      // Set mount
      didMount.current = true;
    } else if (moved) {
      // Update link order
      updateLinks();
    }

    // Move card
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      // Set cards
      setCards((prevCards) =>
        // Update
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ]
        })
      );

      // Set movement
      setMovement(true);
    }, []);

    // Render card
    const renderCard = useCallback((card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          type={card.type}
          text={card.data.title}
          data={card.data}
          moveCard={moveCard}
          onUpdate={(id, updatedData) => {
            // Update card data
            console.log(" - Updating Card: ", id, updatedData);

            // Iterate over data and update data
            for(var i = 0; i < cards.length; i++) {
              // Check if ID's match
              if(cards[i].id == id) {
                cards[i].data = updatedData;
              }
            }

            // Set card data
            setCards(cards);

            // Call handle update hook
            onUpdate(cards);
          }}
          onDelete={(id) => {
            console.log("- Deleting Card: ", id);

            // Copy cards
            let updatedCards = [];

            // Iterate over data and remove deleted
            for(var i = 0; i < cards.length; i++) {
              // Check if ID's match
              if(cards[i].id != id) {
                updatedCards.push(cards[i]);
              }
            }

            // Update position
            for(var i = 0; i < updatedCards.length; i++) {
              updatedCards[i].position = i;
            }

            // Set card data
            setCards(updatedCards);

            // Call handle update hook
            onUpdate(updatedCards);
          }}
        />
      )
    }, []);

    return (
      <>
        <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    )
  }
}
