/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Progress,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table
} from "reactstrap";

// Copy to Clipboard
import copy from 'copy-to-clipboard';

// core components
import ShowcaseHeader from "components/Headers/ShowcaseHeader.js";

// showcase components
import MobileSimulator from "components/Simulator/Mobile.js";

// Tabs
import BiographyCard from "./tabs/BiographyCard";
import LinksCard from "./tabs/LinksCard";
import StyleCard from "./tabs/StyleCard";
import SocialCard from "./tabs/SocialCard";

function SocialProfile({ profile, portfolio, dispatch }) {
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  return (
    <>
      <ShowcaseHeader
        title="Social Profile"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="7">
            <Card className="bg-gradient-primary border-0"
              style={{
                cursor: 'pointer',
                height: 95
              }}
            >
              <CardBody>
                <Row>
                  <Col xl="9">
                    <FormGroup>
                      <InputGroup
                        className={classnames("input-group-merge", {
                          focused: location,
                        })}
                      >
                        <Input
                          placeholder={`https://arthaus.bio/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`}
                          type="text"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fas fa-link" />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="3">
                    <Button size="md" color="default" onClick={() => {
                      // copy to clipboard
                      copy(`https://arthaus.bio/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`);
                    }}>
                      <span className="btn-inner--icon mr-2">
                        <i className="ni ni-single-copy-04" />
                      </span>
                      <span className="btn-inner--text">Copy Link</span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Configure Your Profile</h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { setActiveTab('1'); }}
                    >
                      Links
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { setActiveTab('2'); }}
                    >
                      Bio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '3' })}
                      onClick={() => { setActiveTab('3'); }}
                    >
                      Style
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '4' })}
                      onClick={() => { setActiveTab('4'); }}
                    >
                      Social
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <LinksCard links={(portfolio && portfolio.config && portfolio.config.apps.profile.links) ? portfolio.config.apps.profile.links : []} />
                  </TabPane>
                  <TabPane tabId="2">
                    <BiographyCard />
                  </TabPane>
                  <TabPane tabId="3">
                    <StyleCard />
                  </TabPane>
                  <TabPane tabId="4">
                    <SocialCard />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <MobileSimulator url={`https://arthaus.bio/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedSocialProfile = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(SocialProfile);

export default ConnectedSocialProfile;


/*

<MobileSimulator url={`https://my.arthaus.link/${(portfolio && portfolio.config) ? portfolio.config.handle : ''}`} />

*/
