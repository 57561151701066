/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// Rich Text Editor (Quill)
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardImg,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// API (Axon)
import Arthaus from '@axon/arthaus';

// Load config
import config from "config";

// Firebase
import { firestore, arrayUnion, arrayRemove } from "./../../../../firebase";

// Auth actions
import {
  SET_ACTIVE_COLLECTION,
  SET_ACTIVE_ARTWORK
} from 'store/actions';

// core components
import PortfolioHeader from "components/Headers/PortfolioHeader.js";

// Search Bar
import Search from "components/Search/Search.js";

// Form data
const initialFormState = {
  title: '',
  commentary: ''
}

function ViewCollection({ profile, portfolio, dispatch }) {
  // Get context
  const { id } = useParams();
  console.log("View Collection: ", id);

  // Local States
  const [formData, setFormData] = useState(initialFormState);

  // Get history
  const history = useHistory();

  // States (Navigation)
  const [location, setlocation] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('1');

  // Content Editor
  const [editing, setEditing] = useState(false);

  // Artwork search
  const [artworks, setArtworks] = React.useState([{ id: "1", text: "Artwork Name" }]);

  // Get updated portfolio data
  useEffect(() => {
    // Check active artwork
    if(portfolio && !portfolio.collection.selected) {
      // Query for ID
      firestore.collection("collections").doc(id)
        .onSnapshot((doc) => {
          // Parse data
          let collectionData = doc.data();
          console.log("Collection Data: ", collectionData);

          // Set values
          if(collectionData.content) {
            // set content
            setFormData(collectionData.content);

            // Set commentary
            setCommentary(collectionData.content.commentary ? collectionData.content.commentary : '');
          }

          // Update local store
          dispatch({
            type: SET_ACTIVE_COLLECTION,
            payload: collectionData
          });
        });
    } else if (portfolio && portfolio.collection.selected) {
      // Set values
      if(portfolio.collection.selected.content) {
        // set content
        setFormData(portfolio.collection.selected.content);

        // Set commentary
        setCommentary(portfolio.collection.selected.content.commentary ? portfolio.collection.selected.content.commentary : '');
      }
    }
  }, [portfolio]);

  // Content Editor
  const [commentary, setCommentary] = useState('');
  const onCommentaryUpdated = (newValue) => {
    if (newValue !== commentary) {
      setCommentary(newValue);
    }
  };

  // Update collection
  const saveCollectionContent = async () => {
    // Start edit
    await setEditing(true);

    // Update commentary
    console.log("Updating Collection Content: ", formData.title);

    // Update portfolios
    const collectionsRef = firestore.collection("collections").doc(id);
    const updatedCollection = await collectionsRef.set(
      {
        content: {
          ...formData,
          'commentary': commentary
        }
      },
      { merge: true }
    );

    // Creaet editing
    await setEditing(false);
  }

  // Add artwork (to collection)
  const addToCollection = (artwork) => {
    // Add to collection
    const collectionsRef = firestore.collection("collections").doc(id);
    collectionsRef.update({
      artworks: arrayUnion(artwork)
    });
  }

  // Remove artwork (from collection)
  const removeFromCollection = (artwork) => {
    // Add to collection
    const collectionsRef = firestore.collection("collections").doc(id);
    collectionsRef.update({
      artworks: arrayRemove(artwork)
    });
  }

  return (
    <>
      <PortfolioHeader
        title="Collection Title"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        collapsed={true}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="4">
            <Card>
              <CardBody>
                <CardTitle className="h2 mb-0">
                  {`${(portfolio.collection.selected && portfolio.collection.selected.details) ? portfolio.collection.selected.details.name : ''}`}
                </CardTitle>
                <small className="text-muted">
                  published on Oct 29th, 2021
                </small>

                <Button block color="success" size="lg" type="button" className="mt-4"
                  onClick={() => {
                    // TBD
                  }}>
                  Publish
                </Button>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">Key Themes</h5>
              </CardHeader>
              <CardBody>
                <ListGroup className="list my--3" flush>
                  { portfolio.collection.keywords.map((keyword, index) => (
                    <ListGroupItem className="px-0" key={index}>
                      <Row className="align-items-center">
                        <div className="col">
                          <h5>{keyword.display}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="primary"
                            max="100"
                            value={keyword.value}
                          />
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Configure Collection</h3>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { setActiveTab('1'); }}
                    >
                        Content
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { setActiveTab('2'); }}
                    >
                      Artworks
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row className="mt-4">
                      <Col sm="12">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="collection-content-title"
                              md="2"
                            >
                              Title
                            </Label>
                            <Col md="10">
                              <Input
                                defaultValue="Give this collection a catchy name..."
                                id="collection-content-title"
                                type="text"
                                value={formData.title}
                                onChange={e => setFormData({ ...formData, 'title': e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="collection-content-commentary"
                              md="2"
                            >
                              Commentary
                            </Label>
                            <Col md="10">
                              <ReactQuill
                                theme="snow"
                                value={commentary}
                                onChange={onCommentaryUpdated}
                                style={{
                                  minHeight: 500
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <Row>
                            <Col md="6">
                            </Col>
                            <Col md="6" className="text-right">
                              <Button color="primary" size="md" type="button" style={{ width: 100}}
                                onClick={saveCollectionContent}
                              >
                                Update
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="mt-4">
                      <Col md="12">
                        <Search index="artworks" onSelect={addToCollection} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        { portfolio.collection.artworks.map((artwork, index) => (
                          <Card
                            style={{
                              width: '30%',
                              marginTop: '2.5%',
                              marginLeft: '1%',
                              marginRight: '1%',
                              marginBottom: '2.5%',
                              display: 'inline-block'
                            }}
                          >
                            <CardImg
                              alt="..."
                              src={`https://picasso.arthaus.cloud/cache/artworks/${artwork.id}.webp`}
                              style={{ height: '200px', objectFit: 'cover' }}
                              top
                            />

                            <CardBody
                              style={{
                                height: 100
                              }}
                            >
                              <CardTitle className="h2 mb-0 text-center">
                                {artwork.name}
                              </CardTitle>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                <Col md="6">
                                  <Button block color="primary" size="sm" type="button" block
                                    onClick={() => {
                                      // Query for ID
                                      firestore.collection("artworks").doc(artwork.id)
                                        .onSnapshot((doc) => {
                                          // Parse data
                                          let artworkData = doc.data();
                                          console.log("Artwork Data: ", artworkData);

                                          // Update local store
                                          dispatch({
                                            type: SET_ACTIVE_ARTWORK,
                                            payload: artworkData
                                          });

                                          // Got to review page
                                          history.push(`/admin/artworks/view/${artwork.id}`);
                                        });
                                    }}>
                                    View
                                  </Button>
                                </Col>
                                <Col md="6">
                                  <Button block color="danger" size="sm" type="button" block
                                    onClick={() => {
                                      // Remove from collection
                                      removeFromCollection(artwork);
                                    }}>
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>
                        ))}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedViewCollection = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(ViewCollection);

export default ConnectedViewCollection;


/*


<TabPane tabId="1">
  <Row className="mt-4">
    <Col sm="12">
      <Form>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="artwork-headline"
            md="2"
          >
            Headline
          </Label>
          <Col md="7">
            <Input
              defaultValue="Describe this piece in 5 words or less."
              id="artwork-headline"
              type="text"
            />
          </Col>
          <Col md="3">
            <UncontrolledDropdown group>
              <Button onClick={() => { console.log("yolo"); }} color="primary" size="sm" type="button" style={{
                width: '100%'
              }}>
                Generate
              </Button>
              <DropdownToggle
                caret
                color="primary"
              />
              <DropdownMenu>
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  Settings
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  What is this?
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="artwork-short-description"
            md="2"
          >
            Collection Inspiration
          </Label>
          <Col md="7">
          <Input
            id="artwork-short-description"
            defaultValue="Tell the viewer how, why and when."
            resize="none"
            rows="3"
            type="textarea"
          />
          </Col>
          <Col md="3">
            <UncontrolledDropdown group>
              <Button onClick={() => { console.log("yolo"); }} color="primary" size="sm" type="button" style={{
                width: '100%'
              }}>
                Generate
              </Button>
              <DropdownToggle
                caret
                color="primary"
              />
              <DropdownMenu>
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  Settings
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  What is this?
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-text-input"
            md="2"
          >
            Collection Commentary
          </Label>
          <Col md="10">
            <ReactQuill
              theme="snow"
              value={commentary}
              onChange={onCommentaryUpdated}
              style={{
                minHeight: 500
              }}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col md="6">
          </Col>
          <Col md="6" className="text-right">
            <Button onClick={() => { console.log("yolo"); }} color="default" size="sm" type="button" style={{
              width: 150,
              height: 43
            }}>
              View Examples
            </Button>
            <UncontrolledDropdown group>
              <Button onClick={() => { console.log("yolo"); }} color="primary" size="sm" type="button" style={{
                width: 100
              }}>
                Generate
              </Button>
              <DropdownToggle
                caret
                color="primary"
              />
              <DropdownMenu>
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  Settings
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  // TBD
                }}>
                  What is this?
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </Form>
    </Col>
  </Row>
</TabPane>

*/
