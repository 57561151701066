/*
 *   Mobile Simulator
 */

// React imports
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// Config
import config from "config";

// Authentication
import { firestore } from "./../../firebase";

// Constants
const loadingImage = "https://storage.googleapis.com/avant-garde-cdn/brands/arthaus/ar/frame/loading.png";

// Container - iFrame
function Viewer({ render, url }) {
  // State
  const [loading, setLoading] = React.useState(true);
  //const [contentUrl, setContentUrl] = React.useState(url);

  // Monitor changes to portfolio
  useEffect(() => {
    console.log(" - Re-render: ", render);

    // if(loading) {
    //   setLoading(false);
    // }
  }, [ render ]);

  // Load timeout
  if(loading) {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }

  return (
    <>
      <img
        src={loadingImage}
        className={loading ? '' : 'hide'}
      />
      <iframe
        id="frame_1"
        src={render ? `${url}?env=dev&ts=${render}#${render}` : `${url}?env=dev`}
        onLoad={() => {
          // Load after 1 second
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }}
      ></iframe>
    </>
  )
}

// Mobile Simulator
function MobileSimulator({ profile, portfolio, url, type }) {
  // States
  const [ render, setRender ] = useState(null);

  // Monitor changes to porftolio
  useEffect(() => {
    // Check profile
    if(profile && profile.initialized) {
      // Get updates
      firestore.collection("portfolios").doc(profile.user.uid)
        .onSnapshot((doc) => {
          // Parse data
          const portfolioData = doc.data();
          console.log("Updated Portfolio: ", portfolioData);

          // Update render - add delay
          setTimeout(() => {
            setRender(Date.now());
          }, 500);
        });
    }
  }, [ profile ]);

  // Determine display type
  let content = (type) ? type : 'iframe';

  return (
    <div id="wrapper" style={{
      position: 'relative',
      top: '-200px',
      height: '100%'
    }}>
      <div className="phone view_3" id="phone_1">
      {
        {
          'blank': <img src={loadingImage} style={{ width: '100%', height: '100%' }} />,
          'image': <img src={url} style={{ width: '100%', height: '100%' }} />,
          'iframe': <Viewer render={render} url={url} />
        }[content]
      }
      </div>
    </div>
  )
}

// Connect to store
const ConnectedMobileSimulator = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio
}))(MobileSimulator);

export default ConnectedMobileSimulator;
