// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false
};

// ===========================|| GALLERY REDUCER ||=========================== //

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_GALLERY: {
          console.log("Initializing gallery...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        default:
            return state;
    }
};

export default galleryReducer;
