
// Imports
import React, { useState, useEffect, useRef } from "react";
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'

// Components
import {
  Button,
  Badge,
  Modal
} from "reactstrap";

// Edit components
import { EditHero } from "./components/Hero";
import { EditLink } from "./components/Link";
import { EditFeatured } from "./components/Featured";
import { EditNewlsetter } from "./components/Newsletter";

// Styles
const style = {
  width: '100%',
  marginBottom: 10,
  paddingLeft: 20,
  paddingRight: 20
}

// Render Edit
const renderEdit = (id, type, data, onUpdate) => {
  // Choose default
  let card = type ? type : 'link';

  // Return template
  return(
    <>
      {
        {
          'hero': <EditHero id={id} data={data} onUpdate={onUpdate} />,
          'link': <EditLink id={id} data={data} onUpdate={onUpdate} />,
          'featured': <EditFeatured id={id} data={data} onUpdate={onUpdate} />,
          'newsletter': <EditNewlsetter id={id} data={data} onUpdate={onUpdate} />,
        }[card]
      }
    </>
  )
}

// Card
export const Card = ({ id, type, text, data, index, moveCard, onUpdate, onDelete }) => {
  // Local States
  const [editModal, setEditModal] = React.useState(false);

  // Refrence
  const ref = useRef(null)

  // Drop behavior
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },

    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  // Drag behavior
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  // Edit card data
  const editCard = (card) => {
    console.log("Editing Card: ", card);

    // Open edit modal
    setEditModal(true);
  }

  // Return
  return (
    <div className="flex-item" ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <Button size="lg" block>
        <span style={{
          position: 'absolute',
          left: 0,
          width: 100,
          borderRight: '1px solid white'
        }}>
          <Badge>{type}</Badge>
        </span>
        <span style={{
          paddingLeft: 50
        }}>
          {text}
        </span>
        <span style={{
          position: 'absolute',
          right: 10,
          top: 10
        }}>
          { type == "link" &&
            <Button
              className="btn-neutral btn-round btn-icon"
              href=""
              onClick={(e) => {
                // Delete
                onDelete(id);
              }}
              size="sm"
              style={{
                backgroundColor: '#f5365c',
                border: 'none',
                color: '#ffffff'
              }}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text">Delete</span>
            </Button>
          }
          <Button
            className="btn-neutral btn-round btn-icon"
            href=""
            onClick={(e) => {
              // Edit link
              editCard(data);
            }}
            size="sm"
            style={{
              backgroundColor: '#C7B078',
              border: 'none'
            }}
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-edit" />
            </span>
            <span className="btn-inner--text">Edit</span>
          </Button>
        </span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={editModal}
        toggle={() => setEditModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit {type.charAt(0).toUpperCase() + type.slice(1)} Card
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setEditModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        { renderEdit(id, type, data, onUpdate)}
      </Modal>
    </div>
  )
}
