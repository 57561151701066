/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Imports
import Sample from "views/pages/Sample.js";

// Auth
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

// Portfolio
import Profile from "views/portfolio/profile";
import Artworks from "views/portfolio/artworks";
import ViewArtwork from "views/portfolio/artworks/view";
import Collections from "views/portfolio/collections";
import ViewCollection from "views/portfolio/collections/view";
import Editions from "views/portfolio/editions";
import ViewEdition from "views/portfolio/editions/view";

// Showcase
import SocialProfile from "views/showcase/social";
import PersonalShop from "views/showcase/shop";
import VirtualGallery from "views/showcase/gallery";

const routes = [
  {
    collapse: true,
    name: "Portfolio",
    display: true,
    icon: "ni ni-palette text-default",
    state: "portfolioCollapse",
    views: [
      {
        path: "/profile",
        display: true,
        name: "Home",
        miniName: "A",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/artworks/view/:id",
        display: false,
        component: ViewArtwork,
        layout: "/admin",
      },
      {
        path: "/artworks",
        display: true,
        name: "Artworks",
        miniName: "R",
        component: Artworks,
        layout: "/admin",
      },
      // {
      //   path: "/collections/view/:id",
      //   display: false,
      //   component: ViewCollection,
      //   layout: "/admin",
      // },
      // {
      //   path: "/collections",
      //   display: true,
      //   name: "Collections",
      //   miniName: "T",
      //   component: Collections,
      //   layout: "/admin",
      // },
      // {
      //   path: "/editions/view/:id",
      //   display: false,
      //   component: ViewEdition,
      //   layout: "/admin",
      // },
      // {
      //   path: "/editions",
      //   display: true,
      //   name: "Editions",
      //   miniName: "T",
      //   component: Editions,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    name: "Showcase",
    display: true,
    icon: "ni ni-album-2 text-primary",
    state: "galleryCollapse",
    views: [
      {
        path: "/social",
        display: true,
        name: "Social Profile",
        miniName: "P",
        component: SocialProfile,
        layout: "/admin",
      },
      // {
      //   path: "/shop",
      //   display: true,
      //   name: "Personal Shop",
      //   miniName: "S",
      //   component: PersonalShop,
      //   layout: "/admin",
      // },
      // {
      //   path: "/gallery",
      //   display: true,
      //   name: "Virtual Gallery",
      //   miniName: "G",
      //   component: VirtualGallery,
      //   layout: "/admin",
      // }
    ],
  },
  // {
  //   collapse: true,
  //   name: "Studio",
  //   display: true,
  //   icon: "ni ni-scissors text-primary",
  //   state: "studioCollapse",
  //   views: [
  //     {
  //       path: "/stories",
  //       display: true,
  //       name: "Stories",
  //       miniName: "S",
  //       component: Sample,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/journal",
  //       display: true,
  //       name: "Journal",
  //       miniName: "B",
  //       component: Sample,
  //       layout: "/admin",
  //     }
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Community",
  //   display: true,
  //   icon: "ni ni-building text-primary",
  //   state: "communityCollapse",
  //   views: [
  //     {
  //       path: "/patrons",
  //       display: true,
  //       name: "Patrons",
  //       miniName: "P",
  //       component: Sample,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/newsletter",
  //       display: true,
  //       name: "Newsletter",
  //       miniName: "N",
  //       component: Sample,
  //       layout: "/admin",
  //     }
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Finances",
  //   display: true,
  //   icon: "ni ni-chart-bar-32 text-primary",
  //   state: "financesCollapse",
  //   views: [
  //     {
  //       path: "/sales",
  //       display: true,
  //       name: "Sales Performance",
  //       miniName: "S",
  //       component: Sample,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/payouts",
  //       display: true,
  //       name: "Commission Payouts",
  //       miniName: "P",
  //       component: Sample,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/distribution",
  //       display: true,
  //       name: "Distribution Channels",
  //       miniName: "D",
  //       component: Sample,
  //       layout: "/admin",
  //     }
  //   ],
  // },
  {
    collapse: true,
    name: "Examples",
    display: false,
    icon: "ni ni-ungroup",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        display: false,
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      }
    ]
  },
];

export default routes;
