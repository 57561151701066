/*
 *  Search (Algolia-based)
 */

// Imports
import fetch from 'isomorphic-fetch';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { render } from 'react-dom';
import _ from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';

// API (Axon)
import Arthaus from '@axon/arthaus';

const CACHE = {};
const PER_PAGE = 50;

function SearchArtworks({ index, params, profile, onSelect }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const handleInputChange = (q) => {
    setQuery(q);
  };

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[query];

    // Don't make another request if:
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total_count
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    // makeAndHandleRequest(query, page).then((resp) => {
    //   const options = cachedQuery.options.concat(resp.options);
    //   CACHE[query] = { ...cachedQuery, options, page };
    //
    //   setIsLoading(false);
    //   setOptions(options);
    // });
  };

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].options);
      return;
    }

    setIsLoading(true);

    // Artworks search api
    Arthaus.api.artworks.search({
      query: q,
      artistId: profile.user.artistId,
      take: 10,
      skip: 0
    }, function(artworks) {
      // Format results
      let results = _.map(artworks, (artwork) => ({
        id: artwork.file_name,
        name: artwork.name
      }));

      // Parse into options
      let payload = {
        options: results,
        total_count: artworks.length
      };

      // Cache results
      CACHE[q] = { ...payload, page: 1 };

      setIsLoading(false);
      setOptions(payload.options);
    });

  }, [ profile ]);

  return (
    <AsyncTypeahead
      id="async-pagination-example"
      isLoading={isLoading}
      labelKey="name"
      maxResults={PER_PAGE - 1}
      minLength={2}
      onInputChange={handleInputChange}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      options={options}
      paginate
      placeholder={`Search for ${index}...`}
      renderMenuItemChildren={(option) => (
        <div key={option.id} onClick={() => onSelect && onSelect(option) }>
          <img
            alt={option.name}
            src={`https://picasso.arthaus.cloud/cache/artworks/${option.id}.webp`}
            style={{
              height: '24px',
              marginRight: '10px',
              width: '24px',
            }}
          />
          <span>{option.name}</span>
        </div>
      )}
      useCache={false}
    />
  );
}
// Connect to store
const ConnectedSearchArtworks = connect(state => ({
  profile: state.profile
}))(SearchArtworks);


export default ConnectedSearchArtworks;
