/*
 *   Palette Configurator
 */


// React imports
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// debounce
import _debounce from 'lodash/debounce';

// reactstrap components
import {
 Badge,
 Button,
 Card,
 CardHeader,
 CardFooter,
 DropdownMenu,
 DropdownItem,
 DropdownToggle,
 UncontrolledDropdown,
 Media,
 Pagination,
 PaginationItem,
 PaginationLink,
 Progress,
 Table,
 Container,
 Row,
 Col,
 UncontrolledTooltip
} from "reactstrap";

// Import color selector
import { ChromePicker } from 'react-color'

// Actions
import {
  SET_EDITING,
  SET_UPDATED,
  SET_PALETTE
} from 'store/actions';

// Color picker component
function ColorPicker({ style, updatePalette, role, dispatch }) {
  // Color selection
  const [display, setDisplay] = useState(false);
  const [newColor, setNewColor] = useState(style.palette.current[role] ? style.palette.current[role] : '#FFFFFF');

  // Launch / close
  const handleClick = (e) => {
    e.preventDefault();

    // Set editing
    dispatch({
      type: SET_EDITING,
      payload: !display
    });

    // hide / close
    setDisplay(!display);
  }

  // Launch / close
  const handleClose = (e) => {
    // Set editing
    dispatch({
      type: SET_EDITING,
      payload: false
    });

    setDisplay(false);
  }

  // Helper - debounce
  const debounceFn = useCallback(_debounce(updatePalette, 1000), []);

  // Update color
  const updateColor = (selectedColor) => {
    // Set the color reference
    setNewColor(selectedColor);

    // Set palette
    let updatedPalette = { ...style.palette.current };

    // Change color
    updatedPalette[role] = selectedColor.hex;

    // Update palette
    dispatch({
      type: SET_PALETTE,
      payload: {
        current: style.palette.current,
        updated: updatedPalette
      }
    });

    // Mark as updated
    dispatch({
      type: SET_UPDATED,
      payload: true
    });

    // Preview color (wait for change)
    debounceFn(updatedPalette);
  }

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <div>
      <a
        className="table-action"
        href=""
        onClick={handleClick}
      >
        <i className="fas fa-palette" />
      </a>
      { display ?
        <div style={ popover }>
          <div style={ cover } onClick={handleClose} />
          <ChromePicker color={newColor} onChange={updateColor} />
        </div>
      :
       null
    }
    </div>
  )
}

// Mobile Simulator
function PaletteConfigurator({ style, updatePalette, dispatch }) {
  // Store palette references
  console.log("Style: ", style)
  const [currentPalette, setCurrentPalette] = useState(style.initialized ? style.palette.current : {});
  console.log("Palette - Current: ", currentPalette);

  // Update
  React.useEffect(() => {
    if(style.initialized && !currentPalette.hasOwnProperty("primary")) {
      setCurrentPalette(style.palette.current);
    }
  }, [style.initialized]);

  return (
    <Card>
      <CardHeader className="border-0 mt-4">
        <Row>
          <Col xs="6">
            <h3 className="mb-0">Color Palette</h3>
          </Col>
          <Col className="text-right" xs="6">
            { style.updated &&
              <>
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  href=""
                  onClick={ () => {
                    // Done editing
                    dispatch({
                      type: SET_EDITING,
                      payload: false
                    });

                    // Save changes
                    dispatch({
                      type: SET_UPDATED,
                      payload: false
                    });

                    // Set current with updated
                    setCurrentPalette(style.palette.updated);
                  }}
                  size="md"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-check-bold" />
                  </span>
                  <span className="btn-inner--text">Save</span>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="secondary"
                  href=""
                  onClick={ () => {
                    // Restore old palette
                    updatePalette(currentPalette);
                    dispatch({
                      type: SET_PALETTE,
                      payload: {
                        current: currentPalette,
                        updated: currentPalette
                      }
                    });

                    // Done editing
                    dispatch({
                      type: SET_EDITING,
                      payload: false
                    });

                    // Save changes
                    dispatch({
                      type: SET_UPDATED,
                      payload: false
                    });
                  }}
                  size="md"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="ni ni-fat-remove" />
                  </span>
                  <span className="btn-inner--text">Reset</span>
                </Button>
              </>
            }
          </Col>
        </Row>
      </CardHeader>

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          { (style.editing || style.updated) ?
            <tr>
              <th>Updated</th>
              <th>Current</th>
              <th>Role</th>
              <th />
            </tr>
            :
            <tr>
              <th>Current</th>
              <th>Role</th>
              <th />
            </tr>
          }
        </thead>
        <tbody>
          <tr>
            { (style.editing || style.updated) &&
              <td className="table-user">
                <div
                  className="avatar rounded-circle mr-3"
                  style={{
                    backgroundColor: (style.initialized) ? style.palette.updated.primary : "#FFFFFF"
                  }}
                />
              </td>
            }
            <td className="table-user">
              <div
                className="avatar rounded-circle mr-3"
                style={{
                  backgroundColor: (style.initialized) ? currentPalette.primary : "#FFFFFF"
                }}
              />
            </td>
            <td>
              <span className="text-muted">
                Primary
              </span>
            </td>
            <td className="table-actions">
              { style.initialized &&
                <ColorPicker
                  style={style}
                  updatePalette={updatePalette}
                  role="primary"
                  dispatch={dispatch}
                />
              }
            </td>
          </tr>
          <tr>
            { (style.editing || style.updated) &&
              <td className="table-user">
                <div
                  className="avatar rounded-circle mr-3"
                  style={{
                    backgroundColor: (style.initialized) ? style.palette.updated.secondary : "#FFFFFF"
                  }}
                />
              </td>
            }
            <td className="table-user">
              <div
                className="avatar rounded-circle mr-3"
                style={{
                  backgroundColor: (style.initialized) ? currentPalette.secondary : "#FFFFFF"
                }}
              />
            </td>
            <td>
              <span className="text-muted">
                Secondary
              </span>
            </td>
            <td className="table-actions">
              { style.initialized &&
                <ColorPicker
                  style={style}
                  updatePalette={updatePalette}
                  role="secondary"
                  dispatch={dispatch}
                />
              }
            </td>
          </tr>
          <tr>
            { (style.editing || style.updated) &&
              <td className="table-user">
                <div
                  className="avatar rounded-circle mr-3"
                  style={{
                    backgroundColor: (style.initialized) ? style.palette.updated.accent : "#FFFFFF"
                  }}
                />
              </td>
            }
            <td className="table-user">
              <div
                className="avatar rounded-circle mr-3"
                style={{
                  backgroundColor: (style.initialized) ? currentPalette.accent : "#FFFFFF"
                }}
              />
            </td>
            <td>
              <span className="text-muted">
                Accent
              </span>
            </td>
            <td className="table-actions">
              { style.initialized &&
                <ColorPicker
                  style={style}
                  updatePalette={updatePalette}
                  role="accent"
                  dispatch={dispatch}
                />
              }
            </td>
          </tr>
          <tr>
            { (style.editing || style.updated) &&
              <td className="table-user">
                <div
                  className="avatar rounded-circle mr-3"
                  style={{
                    backgroundColor: (style.initialized) ? style.palette.updated.neutral : "#FFFFFF"
                  }}
                />
              </td>
            }
            <td className="table-user">
              <div
                className="avatar rounded-circle mr-3"
                style={{
                  backgroundColor: (style.initialized) ? currentPalette.neutral : "#FFFFFF"
                }}
              />
            </td>
            <td>
              <span className="text-muted">
                Neutral
              </span>
            </td>
            <td className="table-actions">
              { style.initialized &&
                <ColorPicker
                  style={style}
                  updatePalette={updatePalette}
                  role="neutral"
                  dispatch={dispatch}
                />
              }
            </td>
          </tr>
          <tr>
            { (style.editing || style.updated) &&
              <td className="table-user">
                <div
                  className="avatar rounded-circle mr-3"
                  style={{
                    backgroundColor: (style.initialized) ? style.palette.updated['base-100'] : "#FFFFFF"
                  }}
                />
              </td>
            }
            <td className="table-user">
              <div
                className="avatar rounded-circle mr-3"
                style={{
                  backgroundColor: (style.initialized) ? currentPalette['base-100'] : "#FFFFFF"
                }}
              />
            </td>
            <td>
              <span className="text-muted">
                Base 100
              </span>
            </td>
            <td className="table-actions">
              { style.initialized &&
                <ColorPicker
                  style={style}
                  updatePalette={updatePalette}
                  role="base-100"
                  dispatch={dispatch}
                />
              }
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  )
}

// export default PaletteConfigurator;

// Connect to store
const ConnectedPaletteConfigurator = connect(state => ({
  profile: state.profile,
  portfolio: state.portfolio,
  style: state.style
}))(PaletteConfigurator);

export default ConnectedPaletteConfigurator;


/*

<Button
  className="btn-round btn-icon"
  color="primary"
  href="#pablo"
  id="tooltip443412080"
  onClick={(e) => e.preventDefault()}
  size="sm"
>
  <span className="btn-inner--icon mr-1">
    <i className="fas fa-user-edit" />
  </span>
  <span className="btn-inner--text">Seed from Artwork</span>
</Button>
<UncontrolledTooltip delay={0} target="tooltip443412080">
  Seed from Artwork
</UncontrolledTooltip>


*/
